import React from 'react'
import { Form } from 'pages'
import { Sidebar } from 'components/resource/sidebar'
import schema from '_schema/archiveCycle'
import routes from './routes'
import { BlockCollection } from '../../components/block'
import { ManualNextRevalidatorButton } from '../../components/ManualNextRevalidatorButton'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitions={definitions}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor="title"
      SidebarComponent={Sidebar}
      width={600}
      header={resource => (
        <ManualNextRevalidatorButton
          type="archive_cycles"
          uuid={resource.uuid}
        />
      )}
    >
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        parentIri={iri}
        definitions={definitions}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        statable={true}
        key={`${iri}-blocks`}
      />
    </Form>
  )
}
