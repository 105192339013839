import schema from '_schema/resource'

export const getResourceMediaUrl = (resource) => {
  if (!resource.externalUrl) {
    return `${
      process.env.REACT_APP_BACKEND_ENTRYPOINT
    }${
      schema.endpoint_get_media.replace(':uuid', resource.uuid)
    }`
  }

  return resource.externalUrl
}
