import React, { useState, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormHelperText,
  IconButton,
} from '@material-ui/core'
import { AddAPhotoOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'components/Loader'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { FileManagerModal } from 'components/file_manager/FileManagerModal'

const useStyles = makeStyles(theme => ({
  dialog_content: {
    textAlign: 'center',
  },
  chosen_file: {
    color: theme.palette.text.secondary,
    marginLeft: 3,
  },
  file_buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  send_button: {},
}))

export const UploadDialog = ({
  name,
  type = 'file',
  hint = null,
  endpoint,
  disabled = false,
  validators,
  setValue,
  handleChange,
  setUpdatedAt,
  formUrl,
  formMethod,
  formProcessResponse = null,
  accept = null,
  isOpen,
  handleToggle,
  isManagerAvailable = null,
  restrictManagerMimeType = null,
  handleFileManagerMetadata = null,
  definitions,
  classes = {},
}) => {
  const [state, setState] = useState({
    value: null,
    error: false,
    isFetching: false,
  })
  const fileInputRef = useRef(null)

  const handleInputClick = () => {
    fileInputRef.current.click()
  }

  const handleInputChange = e => {
    const value = e.target.files[0]
    setState(state => ({
      ...state,
      value,
    }))
    validateField(value)
  }

  const handleManagerChoice = (media) => {
    setState({
      value: {
        media,
        name: `${media.dir === '/' ? '' : media.dir}/${media.originalName}`
      },
      error: false,
      isFetching: false
    })
  }

  const handleSendAndMetadata = () => {
    if (!state.value?.media) {
      return
    }

    handleFileManagerMetadata(setValue, state.value.media)
    handleSend()
  }

  const handleSend = () => {
    if (!state.value) {
      return
    }

    const formData = new FormData()

    if (state.value?.media?.uuid) {
      formData.append('uuid', state.value.media.uuid)
    } else {
      formData.append('file', state.value)
    }

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      { body: formData },
      response => {
        const resource = response

        if (formMethod !== 'PUT') {
          uploadSuccess(resource)

          return
        }

        fetchDataHandleAuthError(
          formUrl,
          'PUT',
          { body: JSON.stringify({ [name]: resource }) },
          response => {
            uploadSuccess(resource)

            formProcessResponse && formProcessResponse(response)

            if (!response.updatedAt) {
              return
            }

            setUpdatedAt(response.updatedAt)
          },
          error => {
            uploadFailure(error)
          }
        )
      },
      error => {
        uploadFailure(error)
      },
      { 'Content-Type': 'multipart/form-data' }
    )
  }

  const uploadSuccess = resource => {
    setState({
      value: null,
      error: false,
      isFetching: false,
    })

    handleChange(resource)

    notification('success', 'T_FORM_RECORD_CREATED', 'T_FORM_SUCCESS')

    handleToggle()
  }

  const uploadFailure = error => {
    if (error.response.title === 'AbortError') {
      return
    }

    setState(state => ({
      ...state,
      isFetching: false,
    }))

    notification('error', error.response.detail, error.response.title)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setState(state => ({
          ...state,
          error: false,
        }))

        return
      }

      const valid = validate(validators, value)

      setState(state => ({
        ...state,
        error: !valid.result && valid.message,
      }))
    },
    [validators]
  )

  const mimeType = useMemo(
    () => {
      if (restrictManagerMimeType) {
        return restrictManagerMimeType
      }

      return type === 'file'
        ? null
        : 'image/'
    },
    [restrictManagerMimeType, type]
  )

  const defaultClasses = useStyles()

  return (
    <Dialog onClose={handleToggle} open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitleWithClose onClose={handleToggle}>
        {translate('T_GENERAL_UPLOAD')}
      </DialogTitleWithClose>
      <DialogContent
        className={clsx(defaultClasses.dialog_content, classes.dialog_content)}
      >
        {state.isFetching && <Loader align="center" marginBottom={15} />}
        <div>
          {translate('T_GENERAL_CHOSEN_FILE')}:
          <span className={clsx(defaultClasses.chosen_file, classes.chosen_file)}>
            {state.value ? state.value.name : translate('T_GENERAL_NONE')}
          </span>
        </div>
        <div className={defaultClasses.file_buttons}>
          <IconButton
            color="primary"
            component="span"
            onClick={handleInputClick}
            disabled={state.isFetching || disabled}
          >
            <AddAPhotoOutlined />
          </IconButton>
          {isManagerAvailable && (
            <FileManagerModal
              handleChoice={handleManagerChoice}
              definitions={definitions}
              restrictMimeType={mimeType}
              disabled={disabled}
            />
          )}
        </div>
        <FormHelperText error={!!state.error} disabled={disabled}>
          {translate(state.error || hint)}
        </FormHelperText>
        <input
          type="file"
          name="file"
          ref={fileInputRef}
          accept={
            accept ||
            (type === 'image' ? 'image/jpg,image/jpeg,image/png' : '*')
          }
          style={{ display: 'none' }}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        {handleFileManagerMetadata && (
          <Button
            color="primary"
            className={clsx(defaultClasses.sendButton, classes.sendButton)}
            disabled={
              !state.value?.media || !!state.error || state.isFetching || disabled
            }
            onClick={handleSendAndMetadata}
            variant="contained"
          >
            Wyślij i przekopiuj metadane
          </Button>)}
        <Button
          color="primary"
          className={clsx(defaultClasses.sendButton, classes.sendButton)}
          disabled={
            !state.value || !!state.error || state.isFetching || disabled
          }
          onClick={handleSend}
          variant="contained"
        >
          {translate('T_GENERAL_SEND')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UploadDialog.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['file', 'image']),
  hint: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setUpdatedAt: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
  formMethod: PropTypes.string.isRequired,
  formProcessResponse: PropTypes.func,
  accept: PropTypes.string,
  isOpen: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogContent: PropTypes.string,
    chosenFile: PropTypes.string,
    fileButton: PropTypes.string,
    sendButton: PropTypes.string,
  }),
}
