import { LANGS_IRI } from '_lib/langs'

/** importable */
const schema = {
  endpoint: '/api/artists',
  access: 'artist',
  resource: {
    definition: '#Artist',
  },
  front: {
    [LANGS_IRI.PL]: '/artysci/:slug',
    [LANGS_IRI.EN]: '/en/artists/:slug',
  },
}

export default schema
