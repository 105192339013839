import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { FieldError } from 'components/form/_helpers/fieldError'
import { fields } from 'components/form/fields'
import { useStyles } from './TranslationTab.styles'

export const TranslationTab = ({
  activeTab,
  tabIndex,
  lang,
  properties,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  additional,
  error,
  renderError,
  globalRenderError,
  disabled = false,
  setTranslationValue,
  setAdditional,
  setTranslationError,
  fullWidth = false,
  formWidth = 300,
  formUrl,
  formMethod
}) => {
  const classes = useStyles()

  const setValue = useMemo(() => setTranslationValue(lang), [
    setTranslationValue,
    lang,
  ])

  const setError = useMemo(() => setTranslationError(lang), [
    setTranslationError,
    lang,
  ])

  return (
    <div
      role="tabpanel"
      hidden={activeTab !== tabIndex}
      id={`panel-${tabIndex}`}
      aria-labelledby={`tab-${tabIndex}`}
      className={classes.root}
    >
      {Object.keys(properties).map(name => {
        if (
          typeof properties[name].type === 'string' &&
          !fields[properties[name].type]
        ) {
          throw new FieldError(properties[name].type)
        }

        const FieldComponent =
          typeof properties[name].type === 'string'
            ? fields[properties[name].type]
            : properties[name].type

        const { type, description, hint, validate, ...rest } = properties[name]

        return (
          <div key={name} className={classes.field}>
            <FieldComponent
              name={name}
              type={type}
              label={description}
              hint={hint}
              initialValue={
                initialValue?.[name] !== undefined ? initialValue[name] : null
              }
              additional={additional}
              value={value[name]}
              compareValue={compareValue?.[name]}
              compare={compare}
              error={error[name]}
              renderError={globalRenderError && renderError[name]}
              disabled={disabled}
              validators={validate}
              setValue={setValue}
              setAdditional={setAdditional}
              setError={setError}
              fullWidth={fullWidth}
              formWidth={formWidth}
              formUrl={formUrl}
              formMethod={formMethod}
              translationLang={lang}
              {...rest}
            />
          </div>
        )
      })}
    </div>
  )
}

TranslationTab.propTypes = {
  activeTab: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  properties: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
        .isRequired,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        }),
      ]).isRequired,
      hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      validate: PropTypes.arrayOf(PropTypes.string),
      endpoint: PropTypes.string,
      choices: PropTypes.object,
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          '@id': PropTypes.string.isRequired,
        })
      ),
      titleAccessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    })
  ).isRequired,
  initialValue: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  compareValue: PropTypes.object,
  compare: PropTypes.bool,
  additional: PropTypes.object,
  error: PropTypes.object.isRequired,
  renderError: PropTypes.object.isRequired,
  globalRenderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  setTranslationValue: PropTypes.func.isRequired,
  setAdditional: PropTypes.func.isRequired,
  setTranslationError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
