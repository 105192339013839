import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSidebarOpened: {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 56px)',
      marginLeft: 56,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingLeft: 16,
    paddingRight: 12,
    backgroundColor: theme.elements.topBar,
  },
  sidebarClose: {
    marginRight: 12,
  },
  reloadLoader: {
    marginRight: 15,
  },
  breadcrumbs: {
    display: 'flex',
    marginRight: 'auto',
  },
  skeleton: {
    display: 'flex',
    marginRight: 'auto',
    width: 220,
  },
}))
