import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  TextField,
  FormControl,
  FormHelperText,
  Popper,
  Chip,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const MultipleResourceType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  endpoint = null,
  resources = null,
  titleAccessor,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
}) => {
  const [id] = useState(randomHash())

  const [choices, setChoices] = useState(resources || [])

  const handleChange = (e, options) => {
    setValue(name, options.map(option => option.value))
    validateField(options.map(option => option.value))
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  useEffect(() => {
    if (value === null && !initialValue) {
      setValue(name, [])
    }
  }, [setValue, name, value, initialValue])

  useEffect(() => {
    if (!endpoint || resources) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, resources, setChoices])

  const options = choices.map(resource => ({
    value: resource['@id'],
    title: prop(resource, titleAccessor),
  }))

  const classes = useStyles()

  return (
    <FormControl
      className={clsx(
        classes.formControl,
        fullWidth && classes.formControllFullWidth
      )}
      error={renderError && !!error}
      disabled={disabled || !choices.length}
    >
      <Autocomplete
        id={id}
        name={name}
        options={options}
        multiple
        getOptionLabel={option => option.title}
        onChange={handleChange}
        value={
          getValues(value)?.map(value => ({
            value: value,
            title:
              options.find(option => option.value === value)
                ?.title || '',
          })) || []
        }
        renderInput={params => (
          <TextField
            {...params}
            label={
              translate(label.text || label) +
              (validators && validators.includes('required') ? ' *' : '')
            }
            variant="outlined"
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option.title} {...getTagProps({ index })} />
          ))
        }
        PopperComponent={props => (
          <Popper
            {...props}
            style={{ width: 'auto' }}
            placement="bottom-start"
          />
        )}
        disabled={disabled || !choices.length}
        classes={{ root: classes.autocomplete }}
      />
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            getValues(value).toString() !==
              getValues(compareValue).toString() && classes.compareNeq
          )}
        >
          {compareValue &&
            getValues(compareValue)
              .map(value => {
                const resource = choices.find(
                  resource => resource['@id'] === value
                )

                if (!resource) {
                  return null
                }

                return prop(resource, titleAccessor)
              })
              .join(', ')}
        </div>
      )}
    </FormControl>
  )
}

const getValues = values => {
  if (!values) {
    return []
  }

  return values.map(value => {
    if (typeof value === 'string') {
      return value
    }

    return value['@id']
  })
}

MultipleResourceType.propTypes = {
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string.isRequired,
      })
    ),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string.isRequired,
      })
    ),
  ]),
  compareValue: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string.isRequired,
      })
    ),
  ]),
  compare: PropTypes.bool,
  endpoint: PropTypes.string,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    })
  ),
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
