import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Chip, Avatar } from '@material-ui/core'
import { CheckOutlined, CloseOutlined, MoreHorizOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  chip: {
    color: '#eee',
  },
  avatar: {
    backgroundColor: '#eee',
  },
  icon: {
    color: '#333',
    fontSize: '1.3em',
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  failure: {
    backgroundColor: theme.palette.error.main,
  },
  processing: {
    backgroundColor: theme.palette.info.main,
  },
}))

export const ResultCell = ({ resource, accessor }) => {
  const classes = useStyles()
  const result = prop(resource, accessor)

  return (
    <Chip
      label={translate(
        result === 'success'
          ? 'Udany'
          : result === 'error'
          ? 'Nieudany'
          : 'Wykonywany'
      )}
      className={clsx(
        classes.chip,
        result === 'success'
          ? classes.success
          : result === 'error'
          ? classes.failure
          : classes.processing
      )}
      avatar={
        <Avatar className={classes.avatar}>
          {result === 'success' ? (
            <CheckOutlined className={classes.icon} />
          ) : result === 'error' ? (
            <CloseOutlined className={classes.icon} />
          ) : (
            <MoreHorizOutlined className={classes.icon} />
          )}
        </Avatar>
      }
    />
  )
}

ResultCell.propTypes = {
  resource: PropTypes.object.isRequired,
  accessor: PropTypes.string,
}
