import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '1.5em',
  },
  field: {
    marginBottom: '.85em',
    '& label': {
      fontSize: '1.0em',
    },
    '& p': {
      fontSize: '.9em',
    },
    '& .MuiFormControl-fullWidth': {
      maxWidth: 800,
    },
  },
  recordChanged: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.info.main,
    '&> span': {
      marginLeft: 10,
    },
  },
  buttonsFixed: {
    position: 'fixed',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);',
    background: theme.elements.content,
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridColumnGap: '24px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'calc(100% - 56px)',
    bottom: 0,
    right: 0,
    paddingRight: '24px',
    paddingBottom: '1.5em',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1,
  },
  buttonsFixedOpen: {
    width: 'calc(100% - 240px)',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 56px)',
    },
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}))
