import React, { useCallback, useState, useMemo } from 'react'
import DOMPurify from 'dompurify'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core'
import { ExpandMoreOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { useDispatch } from 'react-redux'
import { commonConstants } from '_constants'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { NotFound } from 'pages/NotFound'
import { Media } from 'pages/Resources/components/Media'
import { ManualNextRevalidatorButton } from 'components/ManualNextRevalidatorButton'
import { LANGS_IRI } from '_lib/langs'
import { translate } from '_helpers/translate'
import { ViewCollection } from './collection/ViewCollection'
import schema from '_schema/exhibit'
import artistRoutes from 'pages/Artists/routes'
import exhibitionRoutes from 'pages/Exhibitions/routes'
import resourceRoutes from 'pages/Resources/routes'
import routes from './routes'
import moment from 'moment'

const useStyles = makeStyles({
  root: {
    fontSize: 16,
  },
  margin_top: {
    marginTop: 20,
  },
  accordion_wrapper: {
    width: '100%',
  },
  data: {
    '&>*': {
      marginTop: 5,
    },
  },
})

const TabPanel = ({ children, value, index, other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
)

export const View = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [dispatch]
  )

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    false,
    setState.isNotEditable,
    dispatchOnFetch
  )

  const [tab, setTab] = useState(0)

  const handleChange = (e, newValue) => {
    setTab(newValue)
  }

  const classes = useStyles()

  const frontRoutes = useMemo(
    () => ({
      PL: `${process.env.REACT_APP_NEXT_ENTRYPOINT}${schema.front[
        LANGS_IRI.PL
      ].replace(':slug', resource?.slug)}`,
      EN: `${process.env.REACT_APP_NEXT_ENTRYPOINT}${schema.front[
        LANGS_IRI.EN
      ].replace(':slug', resource?.slug)}`,
    }),
    [resource]
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.root}>
        <ManualNextRevalidatorButton type="exhibit" uuid={resource.uuid} />
        <h2>{resource.translations[LANGS_IRI.PL].title}</h2>
        <h4>Source ID: {resource.importId}</h4>
        <div
          style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
        >
          {resource.resourcesPreviewData.map(resource => (
            <div
              key={resource.uuid}
              style={{
                marginRight: 15,
                marginBottom: 15,
                backgroundColor: '#eee',
                padding: 5,
              }}
            >
              <div>
                <div style={{ marginBottom: 10 }}>{resource.title}</div>
                <Media resource={resource} />
                <div>{resource.section.title}</div>
              </div>
              <Link
                to={resourceRoutes().view.path.replace(':id', resource.uuid)}
              >
                {translate('Zobacz w panelu')}
              </Link>
            </div>
          ))}
        </div>
        <div style={{ marginTop: 15 }}>
          <b>Linki frontowe:</b>
          <ul>
            <li>
              PL:{' '}
              <a
                href={frontRoutes.PL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {frontRoutes.PL}
              </a>
            </li>
            <li>
              EN:{' '}
              <a
                href={frontRoutes.EN}
                target="_blank"
                rel="noopener noreferrer"
              >
                {frontRoutes.EN}
              </a>
            </li>
          </ul>
        </div>
        <div>
          <b>Resource:</b> {resource.grouped.title}
        </div>
        <div>
          <b>Age restriction:</b> {resource.ageRestriction?.title || 'brak'}
        </div>
        <div>
          <b>Object Number:</b> {resource.objectNumber}
        </div>
        <div>
          <b>Sort Number:</b>
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                resource.sortNumberRaw?.replaceAll(' ', '&nbsp;')
              ),
            }}
          />
        </div>
        <div>
          <b>Last Updated:</b>{' '}
          {resource.importUpdatedAt
            ? moment(resource.importUpdatedAt).format('DD.MM.YYYY HH:mm:ss')
            : null}
        </div>
        <div>
          <b>Display Name [Always Display]:</b>{' '}
          <ul>
            {resource.artistsPreviewData.map(artist => (
              <li key={artist.uuid}>
                <Link to={artistRoutes().view.path.replace(':id', artist.uuid)}>
                  {artist.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <strong>Title:</strong> {resource.translations[LANGS_IRI.PL].title}
        </div>
        <div>
          <b>Title in original language [formatted]:</b>
          <ul>
            {resource.originalTitles.map((originalTitle, index) => (
              <li
                key={index}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(originalTitle),
                }}
              />
            ))}
          </ul>
        </div>
        <div>
          <b>Begin date:</b> {resource.dateFrom}
        </div>
        <div>
          <b>End date:</b> {resource.dateTo}
        </div>
        <div>
          <b>On view:</b> {resource.isOnDisplay ? 'TAK' : 'NIE'}
        </div>
        <div>
          <b>[Related Exhibitions]:</b>{' '}
          <ul>
            {resource.exhibitionsPreviewData.map(exhibition => (
              <li key={exhibition.uuid}>
                <Link
                  to={exhibitionRoutes().edit.path.replace(
                    ':id',
                    exhibition.uuid
                  )}
                >
                  {exhibition.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <b>[Related Objects]:</b>{' '}
          <ul>
            <ViewCollection
              endpoint={schema.subresources.relatedExhibits.endpoint}
              parentIri={resource['@id']}
              render={resource => (
                <li key={resource.uuid}>
                  <Link
                    to={routes().view.path.replace(
                      ':id',
                      resource.exhibit.uuid
                    )}
                  >
                    {resource.exhibit.title} ({resource.exhibit.objectNumber})
                  </Link>
                </li>
              )}
              key={resource['@id']}
            />
          </ul>
        </div>
        <div>
          <b>Locations:</b>
          <ul>
            {resource.locations.map((location, index) => (
              <li key={index}>{location}</li>
            ))}
          </ul>
        </div>
        <div>
          <b>Accession Date:</b>{' '}
          {resource.accessionDate
            ? moment(resource.accessionDate).format('DD.MM.YYYY')
            : null}
        </div>
        <div style={{ marginTop: 15 }}>
          <b>Object Type AAT PL/EN:</b>{' '}
          <ul>
            {resource.typesPreviewData?.map(type => (
              <li key={type.uuid}>
                PL: {type.translations.pl.title} | EN:{' '}
                {type.translations.en.title}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <b>Keywords Person PL/EN:</b>{' '}
          <ul>
            {resource.keywordPersonsPreviewData?.map(keyword => (
              <li key={keyword.uuid}>
                PL: {keyword.translations.pl.title} | EN:{' '}
                {keyword.translations.en.title}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <b>Keywords Event PL/EN:</b>{' '}
          <ul>
            {resource.keywordEventsPreviewData?.map(keyword => (
              <li key={keyword.uuid}>
                PL: {keyword.translations.pl.title} | EN:{' '}
                {keyword.translations.en.title}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <b>Keywords Place PL/EN:</b>{' '}
          <ul>
            {resource.keywordPlacesPreviewData?.map(keyword => (
              <li key={keyword.uuid}>
                PL: {keyword.translations.pl.title} | EN:{' '}
                {keyword.translations.en.title}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <b>Keywords Object PL/EN:</b>{' '}
          <ul>
            {resource.keywordObjectsPreviewData?.map(keyword => (
              <li key={keyword.uuid}>
                PL: {keyword.translations.pl.title} | EN:{' '}
                {keyword.translations.en.title}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <b>Keywords Subject PL/EN:</b>{' '}
          <ul>
            {resource.keywordSubjectsPreviewData?.map(keyword => (
              <li key={keyword.uuid}>
                PL: {keyword.translations.pl.title} | EN:{' '}
                {keyword.translations.en.title}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <b>External media link:</b>{' '}
          <ul>
            {Object.keys(resource.filmLinks).map(key => (
              <li key={key}>
                {key}:{' '}
                <ul>
                  {resource.filmLinks[key]?.length > 0
                    ? resource.filmLinks[key].map((link, index) => (
                        <li key={index}>
                          <a
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.url}
                          </a>{' '}
                          {link.title ? `(${link.title})` : null}
                        </li>
                      ))
                    : null}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <b>Film aspect ratio:</b> {resource.filmAspectRatio}
        </div>
        <Accordion className={classes.margin_top}>
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="translations-content"
            id="translations-header"
          >
            Tłumaczenia
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.accordion_wrapper}>
              <AppBar position="static">
                <Tabs value={tab} onChange={handleChange} aria-label="tabs">
                  <Tab label="PL" />
                  <Tab label="EN" />
                </Tabs>
              </AppBar>
              <TabPanel value={tab} index={0}>
                <div className={classes.data}>
                  <div>
                    <strong>Title translated PL [formatted]:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.PL].titles.map(
                        (title, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(title),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Notes PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].notes}
                  </div>
                  <div>
                    <strong>Related Works PL:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.PL
                      ].notesRelatedWorks.map((note, index) => (
                        <li
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(note),
                          }}
                        />
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Text about the work for www PL:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.PL].descriptions.map(
                        (description, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(description),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Date PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].date}
                  </div>
                  <div>
                    <strong>Object Production Place TGN PL:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.PL
                      ].objectProductionPlace.map((place, index) => (
                        <li key={index}>{place}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Medium PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].medium}
                  </div>
                  <div>
                    <strong>Dimensions PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].dimensions}
                  </div>
                  <div>
                    <strong>Edition PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].edition}
                  </div>
                  <div>
                    <strong>Film Category PL:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.PL].filmCategories.map(
                        (category, index) => (
                          <li key={index}>{category}</li>
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Film Colour PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].filmColor}
                  </div>
                  <div>
                    <strong>Film Sound PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].filmSound}
                  </div>
                  <div>
                    <strong>Film Language Original PL:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.PL].filmLanguages.map(
                        (language, index) => (
                          <li key={index}>{language}</li>
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Film Language Translation PL:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.PL
                      ].filmTranslationLanguages.map((language, index) => (
                        <li key={index}>{language}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Copyright PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].copyright}
                  </div>
                  <div>
                    <strong>Credit Line PL:</strong>{' '}
                    {resource.translations[LANGS_IRI.PL].creditLine}
                  </div>
                  <div>
                    <strong>Financing PL:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.PL].financing.map(
                        (language, index) => (
                          <li key={index}>{language}</li>
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Bibliography PL:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.PL].bibliographies.map(
                        (bibliography, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(bibliography),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Exhibition History PL:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.PL
                      ].exhibitionHistories.map((history, index) => (
                        <li
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(history),
                          }}
                        />
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Audiodescription PL:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.PL
                      ].audioDescriptions.map((description, index) => (
                        <li
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(description),
                          }}
                        />
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Transcription PL:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.PL].transcriptions.map(
                        (transcription, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(transcription),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <div className={classes.data}>
                  <div>
                    <strong>Title translated EN [formatted]:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.EN].titles.map(
                        (title, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(title),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Notes EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].notes}
                  </div>
                  <div>
                    <strong>Related Works EN:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.EN
                      ].notesRelatedWorks.map((note, index) => (
                        <li
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(note),
                          }}
                        />
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Text about the work for www EN:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.EN].descriptions.map(
                        (description, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(description),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Date EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].date}
                  </div>
                  <div>
                    <strong>Object Production Place TGN EN:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.EN
                      ].objectProductionPlace.map((place, index) => (
                        <li key={index}>{place}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Medium EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].medium}
                  </div>
                  <div>
                    <strong>Dimensions EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].dimensions}
                  </div>
                  <div>
                    <strong>Edition EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].edition}
                  </div>
                  <div>
                    <strong>Film Category EN:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.EN].filmCategories.map(
                        (category, index) => (
                          <li key={index}>{category}</li>
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Film Colour EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].filmColor}
                  </div>
                  <div>
                    <strong>Film Sound EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].filmSound}
                  </div>
                  <div>
                    <strong>Film Language Original EN:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.EN].filmLanguages.map(
                        (language, index) => (
                          <li key={index}>{language}</li>
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Film Language Translation EN:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.EN
                      ].filmTranslationLanguages.map((language, index) => (
                        <li key={index}>{language}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Copyright EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].copyright}
                  </div>
                  <div>
                    <strong>Credit Line EN:</strong>{' '}
                    {resource.translations[LANGS_IRI.EN].creditLine}
                  </div>
                  <div>
                    <strong>Financing EN:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.EN].financing.map(
                        (language, index) => (
                          <li key={index}>{language}</li>
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Bibliography EN:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.EN].bibliographies.map(
                        (bibliography, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(bibliography),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                  <div>
                    <strong>Exhibition History EN:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.EN
                      ].exhibitionHistories.map((history, index) => (
                        <li
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(history),
                          }}
                        />
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Audiodescription EN:</strong>{' '}
                    <ul>
                      {resource.translations[
                        LANGS_IRI.EN
                      ].audioDescriptions.map((description, index) => (
                        <li
                          key={index}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(description),
                          }}
                        />
                      ))}
                    </ul>
                  </div>
                  <div>
                    <strong>Transcription EN:</strong>{' '}
                    <ul>
                      {resource.translations[LANGS_IRI.EN].transcriptions.map(
                        (transcription, index) => (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(transcription),
                            }}
                          />
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </TabPanel>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Paper>
  )
}
