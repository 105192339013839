export const formatFileSize = (bytes) => {
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const threshold = 1024

  if (Math.abs(bytes) < threshold) {
    return `${bytes} B`
  }

  let unit = -1

  do {
    bytes /= threshold
    ++unit
  } while (
    Math.round(Math.abs(bytes) * 10) / 10 >= threshold && unit < units.length - 1
  )

  return `${bytes.toFixed(1)} ${units[unit]}`
}
