import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import exhibitSchema from '_schema/exhibit'
import { LANGS_IRI } from '_lib/langs'

export const exhibitionSingleExhibit = exhibition => ({
  name: BLOCK_TYPES_IRI.EXHIBITION_SINGLE_EXHIBIT,
  label: 'Wystawa: pojedynczy eksponat',
  titleAccessor: (resource) => resource?.exhibit?.translations[LANGS_IRI.PL]?.title || '<Brak eksponatu>',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    exhibit: {
      type: FIELD_TYPES.AUTOCOMPLETE,
      titleProperty: (resource) => `${resource.title} (${resource.objectNumber})`,
      searchProperty: 'title',
      description: 'Eksponat',
      endpoint: exhibitSchema.endpoint,
      parent: exhibition,
      parentProperty: 'exhibitions.uuid',
    },
  },
})
