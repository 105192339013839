import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const calendar = () => ({
  name: BLOCK_TYPES_IRI.CALENDAR,
  label: 'Strona główna / Zajawka programu',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    calendarMaxEntries: {
      description: 'Max wpisów',
      type: FIELD_TYPES.NUMBER
    },
  },
})
