import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
// import mediaSchema from '_schema/media'

// const THUMBS_ENDPOINT = '/api/<TYPE>_block_thumbs'

export const museumBranches = type => ({
  name: BLOCK_TYPES_IRI.MUSEUM_BRANCHES,
  label: 'Wizyta / Oddziały muzeum',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    // media: {
    //   type: FIELD_TYPES.IMAGE,
    //   description: 'T_GENERAL_PHOTO',
    //   endpoint: mediaSchema.endpoint,
    //   thumbs: {
    //     endpoint: THUMBS_ENDPOINT.replace('<TYPE>', type),
    //   },
    //   validate: ['maxSize'],
    // },
  },
})
