import React, { useState, useCallback, useEffect } from 'react'
import randomHash from 'random-hash'
import clsx from 'clsx'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const TimeType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
}) => {
  const [id] = useState(randomHash())

  const handleChange = e => {
    const value = e.target.value
    setValue(name, value)
    validateField(value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  return (
    <>
      <TextField
        id={id}
        name={name}
        type="time"
        label={
          translate(label.text || label) +
          (validators && validators.includes('required') ? ' *' : '')
        }
        value={value ? value.replace(' ', 'T') : ''}
        disabled={disabled}
        onChange={handleChange}
        error={renderError && !!error}
        helperText={translate(renderError && error ? error : hint)}
        InputLabelProps={
          label.color
            ? {
                style: {
                  color: label.color,
                },
                shrink: true,
              }
            : {
                shrink: true,
              }
        }
        fullWidth={fullWidth}
        variant="standard"
      />
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </>
  )
}
