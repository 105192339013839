import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/collections',
  access: 'collection',
  resource: {
    definition: '#Collection',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/kolekcja/:slug',
              [LANGS_IRI.EN]: '/collection/:slug',
            },
          },
        },
      },
    },
  },
}

export default schema
