import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const exhibitionArtists = () => ({
  name: BLOCK_TYPES_IRI.EXHIBITION_ARTISTS,
  label: 'Wystawa: artyści',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        menuTitle: {},
        title: {},
      },
    },
    isVisibleInMenu: {},
  },
})
