import React, { useState, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { BlankForm } from 'pages'
import schema from '_schema/managerMedia'

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '&>*': {
      marginLeft: 10,
    }
  }
})

export const MediaMetadataModal = ({ callback, currentMedia, definitions }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(state => !state)
  }

  const [buttonsRef, setButtonsRef] = useState(null)

  const onButtonsRef = useCallback(
    element => {
      setButtonsRef(element)
    },
    [setButtonsRef]
  )

  const handleSuccess = (resource) => {
    handleToggle()
    callback(resource)
  }

  useEffect(() => {
    if (!currentMedia) {
      return
    }

    setIsOpen(true)
  }, [currentMedia])

  const classes = useStyles()

  return (
    <Dialog open={isOpen} fullWidth={true} maxWidth="sm">
      <DialogTitle onClose={handleToggle}>
        Edycja metadanych pliku <strong>{currentMedia?.originalName}</strong>
      </DialogTitle>
      <DialogContent dividers>
        {currentMedia && (
          <BlankForm
            method="PUT"
            resource={currentMedia}
            iri={currentMedia['@id']}
            url={currentMedia['@id']}
            definitionSchema={definitions[schema.forms.edit_media.definition]}
            customResourceSchema={schema.forms.edit_media}
            showSubmitAndStayButton={false}
            handleSuccess={handleSuccess}
            handleCancel={handleToggle}
            buttonContainerRef={buttonsRef}
            width="100%"
          />)}
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons} ref={onButtonsRef} />
      </DialogActions>
    </Dialog>
  )
}
