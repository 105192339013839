import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import footerMenuItemColumnsSchema from '_schema/_enums/footerMenuItemColumn'

const schema = {
  endpoint: '/api/footer_menu_items',
  access: 'footerMenuItem',
  resource: {
    definition: '#FooterMenuItem',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          link: {},
        },
      },
      col: {
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: footerMenuItemColumnsSchema.endpoint,
        validate: ['required'],
      },
    },
  },
}

export default schema
