import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { CreateOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { Title } from 'components/Title'
import { translate } from '_helpers/translate'
import { LANGS_IRI } from '_lib/langs'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 15,
    marginBottom: 18,
    marginLeft: 4,
  },
  container: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '2.0em',
  },
  anchor: {
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '.55em',
  },
  text: {
    marginLeft: '.25em',
  },
}))

export const EditHeader = ({
  resource,
  editPath,
  wrapWithTitle = false,
  classes = {},
}) => {
  const defaultClasses = useStyles()

  return (
    <Wrapper isWrapped={wrapWithTitle} classes={classes}>
      <span className={clsx(defaultClasses.root, classes.root)}>
        {resource.title ||
          resource.translations?.[LANGS_IRI.PL]?.title ||
          translate('T_GENERAL_MISSING_TITLE')}
        <div className={clsx(defaultClasses.container, classes.container)}>
          <Link
            to={editPath.replace(':id', resource.uuid)}
            className={clsx(defaultClasses.anchor, classes.anchor)}
          >
            <CreateOutlined fontSize="small" />
            <span className={clsx(defaultClasses.text, classes.text)}>
              {translate('T_GENERAL_EDIT_DESCRIPTION')}
            </span>
          </Link>
        </div>
      </span>
    </Wrapper>
  )
}

const Wrapper = ({ children, isWrapped }) => {
  return isWrapped ? <Title>{children}</Title> : children
}

EditHeader.propTypes = {
  resource: PropTypes.object.isRequired,
  editPath: PropTypes.string.isRequired,
  wrapWithTitle: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
    anchor: PropTypes.string,
    text: PropTypes.string,
  }),
}
