import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import exhibitGroupSchema from '_schema/_enums/exhibitGroup'
import exhibitSchema from '_schema/exhibit'
import { LANGS_IRI } from '_lib/langs'

const ITEMS_ENDPOINT = '/api/<TYPE>_block_exhibits'

export const collectionPreview = type => ({
  name: BLOCK_TYPES_IRI.COLLECTION_PREVIEW,
  label: 'Zajawka zbiorów',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
        ctaTitle: {
          description: 'CTA: tytuł',
          type: FIELD_TYPES.STRING,
        },
      },
    },
    collectionPreviewExhibitGroup: {
      description: 'Typ',
      type: FIELD_TYPES.RESOURCE,
      titleAccessor: 'title',
      endpoint: `${exhibitGroupSchema.endpoint}?isCollectable=true`,
      validate: ['required'],
    },
    exhibits: {
      description: 'Eksponaty',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: ITEMS_ENDPOINT.replace('<TYPE>', type),
      titleAccessor: (resource) => resource?.exhibit?.translations[LANGS_IRI.PL]?.title || '<Brak eksponatu>',
      properties: {
        exhibit: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: (resource) => `${resource.title} (${resource.objectNumber})`,
          searchProperty: 'title',
          description: 'Eksponat',
          endpoint: exhibitSchema.endpoint,
          generateEndpointFilters: (form, parent) => ({ grouped: parent.collectionPreviewExhibitGroup.value }),
          validate: ['required'],
        }
      },
    },
  },
})
