import { capitalize } from 'lodash'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import { LANGS_IRI } from '_lib/langs'

const CARDS_ENDPOINT = '/api/<TYPE>_block_cards'
const CARDS_ACCORDIONS_ENDPOINT = '/api/<TYPE>_block_card_accordions'
const CARDS_LINKS_ENDPOINT = '/api/<TYPE>_block_card_links'
const CARDS_THUMBS_ENDPOINT = '/api/<TYPE>_block_card_thumbs'

const CARD_ACCORDION_REF = '#<TYPE>BlockCardAccordion'
const CARD_LINK_REF = '#<TYPE>BlockCardLink'

export const cards = type => ({
  name: BLOCK_TYPES_IRI.CARDS,
  label: 'Wizyta / Kafle z akordeonami',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    cards: {
      description: 'Kafle',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: CARDS_ENDPOINT.replace('<TYPE>', type),
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              description: 'Tytuł',
              type: FIELD_TYPES.STRING,
            },
            text: {
              description: 'Tekst',
              type: FIELD_TYPES.TINYMCE,
            },
            subtitle: {
              description: 'Podpis',
              type: FIELD_TYPES.STRING,
            },
          },
        },
        media: {
          type: FIELD_TYPES.IMAGE,
          description: 'Zdjęcie',
          endpoint: mediaSchema.endpoint,
          thumbs: {
            endpoint: CARDS_THUMBS_ENDPOINT.replace('<TYPE>', type),
          },
          validate: ['maxSize'],
        },
        accordions: {
          description: 'Accordiony',
          type: FIELD_TYPES.EMBEDDED_COLLECTION,
          endpoint: CARDS_ACCORDIONS_ENDPOINT.replace('<TYPE>', type),
          titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          $ref: CARD_ACCORDION_REF.replace('<TYPE>', capitalize(type)),
          properties: {
            translations: {
              type: FIELD_TYPES.TRANSLATION,
              properties: {
                title: {
                  description: 'Tytuł',
                  type: FIELD_TYPES.STRING,
                },
                text: {
                  description: 'Tekst',
                  type: FIELD_TYPES.TINYMCE,
                },
              },
            },
          },
        },
        links: {
          description: 'Linki',
          type: FIELD_TYPES.EMBEDDED_COLLECTION,
          endpoint: CARDS_LINKS_ENDPOINT.replace('<TYPE>', type),
          titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          $ref: CARD_LINK_REF.replace('<TYPE>', capitalize(type)),
          properties: {
            translations: {
              type: FIELD_TYPES.TRANSLATION,
              properties: {
                title: {
                  description: 'Tytuł',
                  type: FIELD_TYPES.STRING,
                },
                link: {
                  description: 'Link',
                  type: FIELD_TYPES.STRING,
                },
              },
            },
          },
        },
      },
    },
  },
})
