import React, { useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { BlankForm } from './BlankForm'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { Title } from 'components/Title'
import { Sidebar as OriginalSidebar } from 'components/resource/sidebar'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { commonConstants } from '_constants'
import { NotFound } from 'pages/NotFound'
import { translate } from '_helpers/translate'
import { boolean } from '_helpers/boolean'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
    backgroundColor: 'transparent',
  },
  grid: {
    marginBottom: 70,
  },
  header: {
    marginBottom: 20,
  },
}))

export const Form = ({
  iri = null,
  isNotEditable: verifyIsNotEditable = null,
  collectionPath = null,
  editPath = null,
  storeCollectionId = null,
  isCurrentResource = true,
  wrapWithPaper = true,
  header = null,
  sidebar = false,
  sidebarTitleAccessor = 'title',
  sidebarStatAccessor = 'stat',
  SidebarComponent = null,
  children = null,
  revalidateTag = null,
  ...rest
}) => {
  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      isCurrentResource &&
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [isCurrentResource, dispatch]
  )

  const { resource, isFetching, isNotEditable, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    verifyIsNotEditable,
    setState.isNotEditable,
    dispatchOnFetch
  )

  useEffect(() => {
    if (resource && isCurrentResource && isNotEditable) {
      dispatch({ type: commonConstants.RESET_CURRENT_RESOURCES })
    }

    if (
      resource &&
      rest?.setParentResource &&
      typeof rest.setParentResource === 'function'
    ) {
      rest.setParentResource(resource)
    }
  }, [resource, isCurrentResource, isNotEditable, dispatch, rest])

  const handleSyncUpdatedAt = resource => {
    setState.resource({
      ...resource,
      updatedAt: resource.updatedAt,
    })
  }

  const calculatedCollectionPath = useMemo(
    () =>
      collectionPath
        ? typeof collectionPath === 'function'
          ? resource
            ? collectionPath(resource)
            : null
          : collectionPath
        : null,
    [collectionPath, resource]
  )

  const calculatedStoreCollectionId = useMemo(
    () =>
      storeCollectionId
        ? typeof storeCollectionId === 'function'
          ? resource
            ? storeCollectionId(resource)
            : null
          : storeCollectionId
        : null,
    [storeCollectionId, resource]
  )

  const WrapComponent = wrapWithPaper ? Paper : 'div'
  const Sidebar = SidebarComponent || OriginalSidebar

  const classes = useStyles()

  return isFetching ? (
    <WrapComponent>
      <Loader />
    </WrapComponent>
  ) : isNotEditable ? (
    <Redirect to={calculatedCollectionPath} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div className={classes.container}>
      {header ? (
        <div className={classes.header}>
          {typeof header === 'function' ? header(resource) : header}
        </div>
      ) : (
        <Title className={classes.header}>
          {translate(iri ? 'T_GENERAL_EDIT' : 'T_GENERAL_NEW')}
        </Title>
      )}
      <Grid container spacing={3}>
        <Grid
          item
          xs={sidebar ? 9 : 12}
          className={clsx(sidebar && classes.grid)}
        >
          <Wrapper wrapWithPaper={false}>
            <BlankForm
              resource={resource}
              iri={iri}
              collectionPath={calculatedCollectionPath}
              editPath={editPath}
              storeCollectionId={calculatedStoreCollectionId}
              isCurrentResource={isCurrentResource}
              buttonsFixed={true}
              fetchCompareResource={boolean(
                process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
              )}
              revalidateTag={revalidateTag}
              {...rest}
            />
            {typeof children === 'function' ? children(resource) : children}
          </Wrapper>
        </Grid>
        {sidebar && (
          <Grid item xs={3}>
            <Wrapper wrapWithPaper={wrapWithPaper}>
              <Sidebar
                resource={resource}
                titleAccessor={sidebarTitleAccessor}
                statAccessor={sidebarStatAccessor}
                collectionPath={calculatedCollectionPath}
                handleSyncUpdatedAt={handleSyncUpdatedAt}
                revalidateTag={revalidateTag}
              />
            </Wrapper>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

const Wrapper = ({ children, wrapWithPaper }) =>
  wrapWithPaper ? (
    <Paper withPadding={false}>{children}</Paper>
  ) : (
    <div>{children}</div>
  )

Form.propTypes = {
  iri: PropTypes.string,
  isNotEditable: PropTypes.func,
  collectionPath: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  editPath: PropTypes.string,
  storeCollectionId: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isCurrentResource: PropTypes.bool,
  wrapWithPaper: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  sidebar: PropTypes.bool,
  sidebarTitleAccessor: PropTypes.string,
  sidebarStatAccessor: PropTypes.string,
  SidebarComponent: PropTypes.elementType,
  revalidateTag: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}
