import { LANGS_IRI } from '_lib/langs'

/** importable */
const schema = {
  endpoint: '/api/exhibits',
  access: 'exhibit',
  resource: {
    definition: '#Exhibit',
  },
  front: {
    [LANGS_IRI.PL]: '/prace/:slug',
    [LANGS_IRI.EN]: '/en/artworks/:slug',
  },
  subresources: {
    relatedExhibits: {
      endpoint: '/api/exhibit_related_exhibits',
    },
  },
}

export default schema
