import React from 'react'
import { prop } from '_helpers/prop'

export const IsAvailableCell = ({ resource, accessor }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{prop(resource, accessor) ? 'TAK' : 'NIE'}</span>
    </div>
  )
}
