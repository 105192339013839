import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const ENTRIES_ENDPOINT = '/api/<TYPE>_block_text_entries'

export const glossary = type => ({
  name: BLOCK_TYPES_IRI.GLOSSARY,
  label: 'Słownik pojęć',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: FIELD_TYPES.TINYMCE,
        },
      },
    },
    textEntries: {
      description: 'Słowa',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: ENTRIES_ENDPOINT.replace('<TYPE>', type),
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              description: 'Słowo',
              type: FIELD_TYPES.STRING,
            },
            text: {
              description: 'Opis',
              type: FIELD_TYPES.TINYMCE,
            },
          },
        },
      },
    },
  },
})
