import { StringType } from './StringType'
import { IntegerType } from './IntegerType'
import { NumberType } from './NumberType'
import { FloatType } from './FloatType'
import { TinymceType } from './TinymceType'
import { PasswordRepeatType } from './PasswordRepeatType'
import { BooleanType } from './BooleanType'
import { ResourceType } from './ResourceType'
import { MultipleResourceType } from './MultipleResourceType'
import { DateType } from './DateType'
import { DateTimeType } from './DateTimeType'
import { MapType } from './MapType'
import { TranslationType } from './translation/TranslationType'
import { FileType } from './file/FileType'
import { ImageType } from './ImageType'
import { YoutubeType } from './YoutubeType'
import { ChoiceType } from './ChoiceType'
import { MediaType, MediaImageType, MediaYoutubeType } from './media'
import { CollectionType } from './CollectionType'
import { SlugType } from './SlugType'
import { AutocompleteType } from './AutocompleteType'
import { EmbeddedCollectionType } from './EmbeddedCollectionType'
import { TimeType } from './TimeType'
import { IksorisType } from './IksorisType'
import { JsonDataType } from './JsonDataType'

export const fields = {
  integer: IntegerType,
  number: NumberType,
  float: FloatType,
  decimal: FloatType,
  boolean: BooleanType,
  string: StringType,
  textarea: StringType,
  tinymce: TinymceType,
  choice: ChoiceType,
  date: DateType,
  datetime: DateTimeType,
  time: TimeType,
  password: StringType,
  password_repeat: PasswordRepeatType,
  slug: SlugType,
  resource: ResourceType,
  multiple_resource: MultipleResourceType,
  map: MapType,
  translation: TranslationType,
  file: FileType,
  image: ImageType,
  youtube: YoutubeType,
  autocomplete: AutocompleteType,
  'media[type]': MediaType,
  'media[image]': MediaImageType,
  'media[youtube]': MediaYoutubeType,
  collection: CollectionType,
  embedded_collection: EmbeddedCollectionType,
  iksoris: IksorisType,
  json_data: JsonDataType,
}
