import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Button, makeStyles } from '@material-ui/core'
import { Paper } from 'components/Paper'
import { Title } from 'components/Title'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { standard } from 'components/table/_columns/standard'
import { ResultCell } from './table/cells/ResultCell'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { WarningButton } from 'components/WarningButton'
import { LogFileCell } from './table/cells/LogFileCell'
import { MessageCell } from './table/cells/MessageCell'
import { translate } from '_helpers/translate'
import { commonConstants } from '_constants'
import schema from '_schema/importEntry'
import importerSchema from '_schema/importerState'
import routes from './routes'

const useStyles = makeStyles({
  manual_import: {
    marginTop: 10,
    '&> *': {
      marginRight: 15,
    },
  },
})

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('message'),
        width: '70%',
        Cell: MessageCell,
      },
      {
        ...standard('result'),
        width: '10%',
        Cell: ResultCell,
      },
      {
        header: 'Data',
        ...standard('createdAt'),
        width: '13%',
        Cell: LogFileCell,
      },
    ],
    []
  )

  const [state, setState] = useResourceState()

  useResourceFetch(
    importerSchema.endpoint,
    state.resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  const dispatch = useDispatch()

  const handleManualImport = () => {
    fetchDataHandleAuthError(
      importerSchema.endpoint_start,
      'PUT',
      {
        body: JSON.stringify({}),
      },
      () => {
        notification('success', 'Import rozpoczęty', 'T_FORM_SUCCESS')

        setTimeout(() => {
          dispatch({ type: commonConstants.INIT_RELOAD })
        }, 1500)
      },
      error => {
        notification('error', error.response.detail, error.response.title)

        setTimeout(() => {
          dispatch({ type: commonConstants.INIT_RELOAD })
        }, 1500)
      }
    )
  }

  const handleUnlockImport = () => {
    fetchDataHandleAuthError(
      importerSchema.endpoint_reset,
      'PUT',
      {
        body: JSON.stringify({}),
      },
      () => {
        notification('success', 'Import odblokowany', 'T_FORM_SUCCESS')

        setTimeout(() => {
          dispatch({ type: commonConstants.INIT_RELOAD })
        }, 1500)
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleSetOvewrite = () => {
    fetchDataHandleAuthError(
      importerSchema.endpoint,
      'PUT',
      {
        body: JSON.stringify({
          isOverwrite: !state.resource.isOverwrite,
        }),
      },
      response => {
        notification(
          'success',
          state.resource.isOvewrite ? 'Tryb wyłączony' : 'Tryb włączony',
          'T_FORM_SUCCESS'
        )

        setState.resource(response)
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  return (
    <Paper>
      <Title>{translate(routes().index.title)}</Title>
      <div className={classes.manual_import}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleManualImport}
        >
          Rozpocznij manualny import
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleUnlockImport}
        >
          Odblokuj manualny import
        </Button>
        {state.resource && (
          <WarningButton
            isWarning={state.resource.isOverwrite}
            onClick={handleSetOvewrite}
          >
            {state.resource.isOverwrite
              ? 'Wyłącz tryb nadpisywania'
              : 'Włącz tryb nadpisywania'}
          </WarningButton>
        )}
      </div>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        autoWidth={false}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
