import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import { LANGS_IRI } from '_lib/langs'

const schema = {
  endpoint: '/api/home_slides',
  access: 'homeSlide',
  resource: {
    definition: '#HomeSlide',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          ctaTitle: {},
          ctaLink: {},
          redAlert: {},
          greenAlert: {},
          alt: {},
        },
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: mediaSchema.endpoint,
        thumbs: { endpoint: '/api/home_slide_thumbs' },
        validate: ['maxSize'],
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              title: media.translations[LANGS_IRI.PL].title,
              alt: media.translations[LANGS_IRI.PL].alt
            },
            [LANGS_IRI.EN]: {
              title: media.translations[LANGS_IRI.EN].title,
              alt: media.translations[LANGS_IRI.EN].alt
            }
          })
        }
      },
      dates: {},
    },
  },
}

export default schema
