import arrayMove from 'array-move'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { constants } from 'components/table/_state'
import { performTagRevalidation } from '_helpers/performTagRevalidation'

export const performManualOrderChange = (
  items,
  selected,
  oldIndex,
  newIndex,
  dispatch,
  revalidateTag
) => {
  const oldOrd = items[oldIndex].ord
  const newOrd = items[newIndex].ord

  dispatch({ type: constants.MANUAL_ORDER_CHANGE_START })

  fetchDataHandleAuthError(
    items[oldIndex]['@id'],
    'PUT',
    {
      body: JSON.stringify({
        ord: newOrd,
      }),
    },
    () => {
      dispatch({
        type: constants.MANUAL_ORDER_CHANGE_SUCCESS,
        payload: {
          items: arrayMove(items, oldIndex, newIndex).map(item => ({
            ...item,
            ord:
              item.ord === oldOrd
                ? newOrd
                : oldOrd > newOrd
                ? item.ord >= newOrd && item.ord < oldOrd
                  ? item.ord + 1
                  : item.ord
                : item.ord <= newOrd && item.ord > oldOrd
                ? item.ord - 1
                : item.ord,
          })),
          selected: arrayMove(selected, oldIndex, newIndex),
        },
      })

      revalidateTag && performTagRevalidation(revalidateTag)

      notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
    },
    error => {
      dispatch({ type: constants.MANUAL_ORDER_CHANGE_FAILURE })
      notification(
        'error',
        error.response.violations.length ? 'Error' : error.response.detail,
        error.response.title
      )
    }
  )
}
