const schema = {
  endpoint: '/api/import_entries',
  log_endpoint: '/api/import_entries/:uuid/log',
  access: 'importEntry',
  resource: {
    definition: '#ImportEntry',
  },
}

export default schema
