import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/branches',
  access: 'branch',
  resource: {
    definition: '#Branch',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          address: {
            type: FIELD_TYPES.TINYMCE,
          },
          openingHours: {
            description: 'Godziny otwarcia do komponentu oddziałów',
            type: FIELD_TYPES.TINYMCE,
          },
          lastAdmission: {
            type: FIELD_TYPES.TINYMCE,
          },
          ctaTitle: {},
          ctaLink: {},
        },
      },
      mondayTimeFrom: { type: FIELD_TYPES.TIME },
      mondayTimeTo: { type: FIELD_TYPES.TIME },
      tuesdayTimeFrom: { type: FIELD_TYPES.TIME },
      tuesdayTimeTo: { type: FIELD_TYPES.TIME },
      wednesdayTimeFrom: { type: FIELD_TYPES.TIME },
      wednesdayTimeTo: { type: FIELD_TYPES.TIME },
      thursdayTimeFrom: { type: FIELD_TYPES.TIME },
      thursdayTimeTo: { type: FIELD_TYPES.TIME },
      fridayTimeFrom: { type: FIELD_TYPES.TIME },
      fridayTimeTo: { type: FIELD_TYPES.TIME },
      saturdayTimeFrom: { type: FIELD_TYPES.TIME },
      saturdayTimeTo: { type: FIELD_TYPES.TIME },
      sundayTimeFrom: { type: FIELD_TYPES.TIME },
      sundayTimeTo: { type: FIELD_TYPES.TIME },
    },
  },
}

export default schema
