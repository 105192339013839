import React, { useMemo } from 'react'
import { Form } from 'pages'
import { Sidebar } from 'components/resource/sidebar'
import { BlockCollection } from 'components/block'
import { EmbeddedCollection } from 'components/embedded'
import { ManualNextRevalidatorButton } from 'components/ManualNextRevalidatorButton'
import schema from '_schema/exhibition'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const blockTypes = useMemo(
    () => schema.subresources.block.types(match.params.id),
    [match.params.id]
  )

  return (
    <Form
      definitions={definitions}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor="title"
      SidebarComponent={Sidebar}
      width={600}
      header={resource => (
        <ManualNextRevalidatorButton type="exhibition" uuid={resource.uuid} />
      )}
    >
      <EmbeddedCollection
        endpoint={schema.subresources.relatedEvents.endpoint}
        parentIri={iri}
        properties={schema.subresources.relatedEvents.properties}
        definitions={definitions}
        definitionSchema={
          definitions[schema.subresources.relatedEvents.definition]
        }
        statable={true}
        headerTitle="Wydarzenia powiązane"
        titleAccessor="event.title"
        key={`${iri}-related-events`}
      />
      <EmbeddedCollection
        endpoint={schema.subresources.relatedExhibitions.endpoint}
        parentIri={iri}
        properties={schema.subresources.relatedExhibitions.properties}
        definitions={definitions}
        definitionSchema={
          definitions[schema.subresources.relatedExhibitions.definition]
        }
        statable={true}
        headerTitle="Wystawy powiązane"
        titleAccessor="exhibition.title"
        key={`${iri}-related-exhibitions`}
      />
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        parentIri={iri}
        definitions={definitions}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={blockTypes}
        statable={true}
        key={iri}
      />
    </Form>
  )
}
