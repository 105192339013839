import {
  text,
  textWithPhoto,
  photo,
  newestNews,
  calendar,
  audioPlayer,
  videoPlayer,
  accordions,
  cards,
  files,
  relatedLinks,
  logoGroups,
  imageGallery,
  imageCarousel,
  images,
  currentExhibitions,
  contentModules,
  contentSlider,
  blogEntries,
  museumBranches,
  textEntries,
  glossary,
  magazineArticles,
  magazineTwoArticles,
  formset,
  newsletter,
  collectionPreview,
  jobOffers,
  archiveEvents,
  relatedNews,
  cinemaEvents,
  cinemaCycle,
  cinemaAgenda,
  team,
  authors,
} from '_schema/_blocks'

const schema = type => ({
  types: [
    text(),
    images(type),
    imageGallery(type),
    imageCarousel(type),
    audioPlayer(type),
    videoPlayer(),
    textWithPhoto(type),
    photo(type),
    contentSlider(type),
    contentModules(type),
    accordions(type),
    textEntries(type),
    glossary(type),
    files(type),
    relatedLinks(type),
    logoGroups(type),
    authors(type),
    newestNews(),
    blogEntries(),
    magazineArticles(),
    magazineTwoArticles(),
    relatedNews(type),
    formset(),
    newsletter(),
    team(type),
    collectionPreview(type),
    jobOffers(type),
    cards(type),
    currentExhibitions(),
    museumBranches(type),
    cinemaEvents(),
    cinemaCycle(),
    cinemaAgenda(),
    calendar(),
    archiveEvents(),
  ],
})

export default schema
