import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, IconButton, alpha } from '@material-ui/core'
import UploadIcon from '@material-ui/icons/Backup'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import ErrorIcon from '@material-ui/icons/HighlightOff'
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined'
import { authHeader } from '_helpers/authHeader'
import { formatFileSize } from '_helpers/formatFileSize'
import schema from '_schema/managerMedia'

const useStyles = makeStyles(theme => ({
  state: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    color: 'white',
    '&.success': {
      backgroundColor: alpha('#5e5', 0.5),
    },
    '&.error': {
      backgroundColor: alpha('#e55', 0.5),
    },
    '& svg': {
      fontSize: '5em',
    },
  },
  progress_bar: {
    display: 'block',
    padding: 2,
    height: 14,
    width: '100%',
    border: '1px solid #333',
    borderRadius: 3,
  },
  progress_bar_fill: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    width: '50%',
    borderRadius: 3,
  },
}))

export const Uploader = ({ currentDir, callback }) => {
  const [files, setFiles] = useState([])
  const [progress, setProgress] = useState([])
  const [state, setState] = useState([])

  const setSuccess = (index) => {
    setState(state => ({ ...state, [index]: true }))
  }

  const setError = (index, message) => {
    setState(state => ({ ...state, [index]: message }))
  }

  const clear = () => {
    setFiles([])
    setProgress([])
    setState([])
  }

  async function start(files) {
    for (let i = 0; i < files.length; i++) {
      const response = await upload(files[i], i)

      if (response.status === 201) {
        setSuccess(i)

        continue
      }

      const body = JSON.parse(response.body)

      setError(i, body['hydra:description'].replace('file: ', ''))
    }

    callback()
  }

  const upload = (file, index) => {
    const header = authHeader()

    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest()

      xhr.upload.addEventListener('progress', e => {
        setProgress(state => ({ ...state, [index]: e.loaded / e.total }))
      })

      xhr.addEventListener('load', () =>
        resolve({ status: xhr.status, body: xhr.responseText })
      )

      xhr.addEventListener('error', () => {
        setError(index, `Błąd przesyłania pliku ${file.name}`)
      })

      xhr.addEventListener('abort', () => {})

      xhr.open('POST', `${process.env.REACT_APP_BACKEND_ENTRYPOINT}${schema.endpoints.media}`, true)
      xhr.setRequestHeader('Authorization', header.Authorization)

      const formData = new FormData()
      formData.append('dir', currentDir)
      formData.append('file', file)

      xhr.send(formData)
    })
  }

  /** DROPZONE */

  const dropzoneRef = useRef(null)

  useEffect(() => {
    if (null === dropzoneRef.current) {
      return
    }

    dropzoneRef.current.addEventListener('dragover', handleDragOver)
    dropzoneRef.current.addEventListener('drop', handleDrop)

    const current = dropzoneRef.current

    return () => {
      if (null === current) {
        return
      }

      current.removeEventListener('dragover', handleDragOver)
      current.removeEventListener('drop', handleDrop)
    }
  })

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()

    clear()

    const files = Array.from(e.dataTransfer.files)

    if (files?.length > 0) {}

    setFiles(files)
    start(files)
  }

  /** INPUT */

  const handleInput = e => {
    clear()

    const files = Array.from(e.target.files)

    setFiles(files)
    start(files)
  }

  const classes = useStyles()

  return (
    <div>
      <div
        ref={dropzoneRef}
        style={{ padding: 50, border: '2px #c3c3c3 dashed', borderRadius: 12 }}
      >
        <div>
          <input
            id="icon-button-file"
            type="file"
            multiple
            onChange={handleInput}
            style={{ display: 'none' }}
          />

          <label htmlFor="icon-button-file">
            <IconButton color="primary" component="span">
              <UploadIcon />
            </IconButton>
          </label>
          <span>Kliknij lub przeciągnij pliki, aby rozpocząć upload</span>
        </div>
      </div>

      <Grid container style={{ marginTop: 20 }} spacing={5}>
        {files.map((file, index) => (
          <Grid item key={index} style={{ marginBottom: '10px' }}>
            <div style={{ position: 'relative', width: 150 }}>
              {isImage(file.name)
                ? (
                  <img
                    alt=""
                    style={{ maxHeight: 160, width: 150 }}
                    src={URL.createObjectURL(file)}
                  />
                ) : (
                  <div style={{ width: 150, textAlign: 'center' }}>
                    <DescriptionOutlinedIcon
                      style={{ width: 100, height: 'auto' }}
                    />
                  </div>
                )}
              <div
                style={{
                  wordBreak: 'break-word',
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                {file.name}
                <br />
                {formatFileSize(file.size)}
              </div>

              {state[index] === true &&  (
                <div className={`${classes.state} success`}>
                  <SuccessIcon />
                </div>
              )}
              {typeof state[index] === 'string' && (
                <>
                  <div className={`${classes.state} error`}>
                    <ErrorIcon />
                  </div>
                  <span style={{ color: '#e55', wordWrap: 'break-word' }}>
                    {state[index]}
                  </span>
                </>
              )}
            </div>

            <div className={classes.progress_bar}>
              <div
                className={classes.progress_bar_fill}
                style={{
                  width: `${
                    progress && progress[index]
                      ? progress[index] * 100
                      : 0
                  }%`,
                }}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

const isImage = (fileName) => {
  return ['jpeg', 'jpg', 'png', 'gif', 'webp'].includes(
    fileName
      .split('.')
      .pop()
      .toLowerCase()
  )
}
