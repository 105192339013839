import { useReducer, useEffect } from 'react'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { constants } from 'pages/Exhibits/collection/_state'
import buildUrl from 'build-url'

const PER_PAGE = 30

const initialState = {
  items: [],
  init: true,
  isFetching: true,
}

export const useViewCollectionFetch = (reducer, endpoint, parentIri) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: constants.FETCH_START })

    const controller = new AbortController()
    const { signal } = controller

    fetchPage(endpoint, parentIri, 1, signal, dispatch)

    return () => controller.abort()
  }, [endpoint, parentIri])

  return [state, dispatch]
}

const fetchPage = (endpoint, parentIri, page, signal, dispatch) => {
  const url = buildUrl(endpoint, {
    queryParams: {
      'iri[parent]': parentIri,
      page,
      perPage: PER_PAGE,
    },
  })

  fetchDataHandleAuthError(
    url,
    'GET',
    { signal },
    response => {
      const max = Math.ceil(response['hydra:totalItems'] / PER_PAGE)

      dispatch({
        type: constants.FETCH_SUCCESS,
        payload: {
          items: response['hydra:member'],
          isFetchingCompleted: page >= max,
        },
      })

      if (page >= max) {
        return
      }

      fetchPage(endpoint, parentIri, page + 1, signal, dispatch)
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      dispatch({ type: constants.FETCH_FAILURE })
      notification('error', error.response.detail, error.response.title)
    }
  )
}
