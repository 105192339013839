import { constants } from './constants'

export const reducer = (state, action) => {
  switch (action.type) {
    case constants.FETCH_START:
      return {
        ...state,
        isFetching: true,
      }
    case constants.FETCH_SUCCESS:
      return {
        ...state,
        items: [...state.items, ...action.payload.items],
        init: false,
        isFetching: !action.payload.isFetchingCompleted,
      }
    case constants.FETCH_FAILURE:
      return {
        ...state,
        init: false,
        isFetching: false,
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}
