import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import routes from '../../routes'
import { History } from '@material-ui/icons'
import { prop } from '../../../../_helpers/prop'

export const ScheduleCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const redirectToSchedule = () => {
    history.push(routes().schedules.path.replace(':id', resource['uuid']))
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton onClick={redirectToSchedule}>
        <History />
      </IconButton>
      <span>{prop(resource, accessor)}</span>
    </div>
  )
}

ScheduleCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
