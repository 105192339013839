import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/publishing_house_series',
  access: 'publishingHouseSerie',
  resource: {
    definition: '#PublishingHouseSerie',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
        },
      },
    },
  },
}

export default schema
