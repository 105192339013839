import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { ArrowUpwardOutlined, ArrowDownwardOutlined } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline',
    marginLeft: 8,
  },
  icon: {
    fontSize: 16,
    verticalAlign: 'middle',
    //color: theme.palette.disabled,
    color: theme.elements.table_order_by,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
}))

export const OrderBy = ({ column, order = null, handleSort }) => {
  const classes = useStyles()

  const handleAscSort = () => {
    handleSort(column, 'asc')
  }

  const handleDescSort = () => {
    handleSort(column, 'desc')
  }

  return (
    <div className={classes.root}>
      <ArrowUpwardOutlined
        className={clsx(classes.icon, order === 'asc' && classes.active)}
        onClick={handleAscSort}
      />
      <ArrowDownwardOutlined
        className={clsx(classes.icon, order === 'desc' && classes.active)}
        onClick={handleDescSort}
      />
    </div>
  )
}

OrderBy.propTypes = {
  column: PropTypes.string.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']),
  handleSort: PropTypes.func.isRequired,
}
