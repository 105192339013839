import _block from './_block'
import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/cinema_events',
  access: 'cinemaEvent',
  resource: {
    definition: '#CinemaEvent',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          infoLanguage: {
            description: '[INFORMACJE] Język',
          },
          infoGenre: {
            description: '[INFORMACJE] Gatunek',
          },
          infoDirector: {
            description: '[INFORMACJE] Reżyseria',
          },
          infoDuration: {
            description: '[INFORMACJE] Czas trwania',
          },
          infoCast: {
            description: '[INFORMACJE] Obsada',
          },
          infoCountry: {
            description: '[INFORMACJE] Kraj',
          },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/kino/wydarzenia/:slug',
              [LANGS_IRI.EN]: '/cinema/events/:slug',
            },
          },
        },
      },
      infoTitlePolish: {
        description: '[INFORMACJE] Tytuł polski',
      },
      infoTitleOriginal: {
        description: '[INFORMACJE] Tytuł oryginalny',
      },
      infoTitleInternational: {
        description: '[INFORMACJE] Tytuł międzynarodowy',
      },
      infoYear: {
        type: FIELD_TYPES.INTEGER,
        description: '[INFORMACJE] Rok produkcji',
      },
      cycle: {
        description: 'Cykl',
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: `/api/cinema_cycles?pagination=false`,
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: mediaSchema.endpoint,
        thumbs: {
          endpoint: '/api/cinema_event_thumbs',
        },
        validate: ['maxSize'],
      },
      video: {
        description: 'Video',
        type: FIELD_TYPES.FILE,
        endpoint: mediaSchema.endpoint,
        accept: 'video/*',
        restrictManagerMimeType: 'video/',
        validate: ['maxSize'],
      },
      easyReadPdfFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik pdf easy-to-read',
        endpoint: mediaSchema.endpoint,
        accept: 'application/pdf',
        restrictManagerMimeType: 'application/pdf',
        validate: ['maxSize'],
      },
      audioGuideFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik audioprzewodnika',
        endpoint: mediaSchema.endpoint,
        accept: 'audio/*',
        restrictManagerMimeType: 'audio/',
        validate: ['maxSize'],
      },
      audioDescriptionFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik audiodeskrypcji',
        endpoint: mediaSchema.endpoint,
        accept: 'audio/*',
        restrictManagerMimeType: 'audio/',
        validate: ['maxSize'],
      },
      signLanguageFile: {
        type: FIELD_TYPES.FILE,
        description: 'Plik PJM',
        endpoint: mediaSchema.endpoint,
        accept: 'video/*',
        restrictManagerMimeType: 'video/',
        validate: ['maxSize'],
      },
      iksorisId: {
        type: FIELD_TYPES.IKSORIS,
        endpoint: '/api/iksoris/events',
        titleAccessor: 'name',
        label: 'Wydarzenie',
      },
    },
  },
  subresources: {
    images: {
      endpoint: '/api/cinema_event_images',
      definition: '#CinemaEventImage',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            alt: {
              description: 'T_GENERAL_ALT',
            },
          },
        },
        media: {
          type: 'image',
          description: 'Zdjęcie',
          endpoint: mediaSchema.endpoint,
          validate: ['maxSize'],
          thumbs: {
            endpoint: '/api/cinema_event_image_thumbs',
          },
        },
      },
    },
    block: {
      endpoint: '/api/cinema_event_blocks',
      definition: '#CinemaEventBlock',
      types: [..._block('cinema_event').types],
    },
  },
}

export default schema
