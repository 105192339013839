import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
// import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/cinemaEvent'
import scheduleSchema from '_schema/cinemaEventSchedule'
// import itemRoutes from './routes'
import { operations } from '../../components/table/_columns/operations'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'
import { StatusCell } from 'components/table/cells/StatusCell'
import { IsAvailableCell } from './table/cells/IsAvailableCell'

export const ScheduleCollection = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: 'Czas',
        accessor: 'time',
        sortable: true,
        width: '35%',
        Cell: DatetimeCell,
      },
      {
        header: 'Dostępny',
        accessor: 'isAvailable',
        width: '15%',
        Cell: IsAvailableCell,
      },
      {
        header: 'Liczba miejsc',
        accessor: 'availablePlaces',
        width: '15%',
      },
      {
        ...operations('time', false, false, false),
        width: '10%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader hideButton={true} buttonPath="" buttonTitle="">
        Harmonogram wydarzenia: {resource?.title}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        //path={`paths.${itemSchema.endpoint}.get`}
        endpoint={`${scheduleSchema.endpoint}`}
        definition={scheduleSchema.resource.definition}
        storeCollectionId={`${schema.endpoint}?iri[parent]=${parentIri}`}
        columns={itemColumns}
        customResourceSchema={scheduleSchema.resource}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
          {
            name: 'order[time]',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        defaultSorters={{ time: 'desc' }}
        orderable={false}
        numerable={false}
        // isRowLinkable={true}
        // editPath={itemRoutes().scheduleEdit.path}
        autoWidth={false}
        paginable={false}
      />
    </Paper>
  )
}
