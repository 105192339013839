import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const currentExhibitions = () => ({
  name: BLOCK_TYPES_IRI.CURRENT_EXHIBITIONS,
  label: 'Wizyta / Aktualne wystawy',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
  },
})
