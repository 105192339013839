import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { useSelector } from 'react-redux'

export const JsonDataType = ({
  name,
  type = 'string',
  label = 'Json import data',
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setAdditional = null,
  setError,
  syncWithAdditional = false,
  fullWidth = false,
  onlyOpenform = true,
}) => {
  const profile = useSelector(state => state.profile)
  /*
  todo handle changes
  const handleChange = e => {
    setValue(name, e.target.value)
    validateField(value)

    syncWithAdditional && setAdditional && setAdditional({ [name]: value })
  }
   */

  const validateField = useCallback(
    value => {
      // todo
      setError(name, false)

      return
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)

    syncWithAdditional &&
      setAdditional &&
      setAdditional({ [name]: initialValue })
  }, [validateField, initialValue, syncWithAdditional, setAdditional, name])

  /*
  const additional = {
    minRows: TEXTAREA_ROWS,
    maxRows: TEXTAREA_MAX_ROWS,
    multiline: true,
    FormHelperTextProps: { style: { marginLeft: 0 } },
  }

  const classes = useStyles()
   */

  if (onlyOpenform && !profile.group?.isOpenform) {
    return null
  }

  const labelTitle = onlyOpenform ? '[OF] ' : ''

  const renderData = (data, disabled, path = '') => {
    if (typeof data === 'object' && !Array.isArray(data)) {
      return Object.keys(data).map(key => (
        <Box key={key} ml={2} mb={1}>
          <Typography variant="body1">
            <strong>{key}:</strong>
          </Typography>
          {renderData(data[key], disabled, `${path}/${key}`)}
        </Box>
      ))
    } else if (Array.isArray(data)) {
      return data.map((item, index) => (
        <Box key={index} ml={2} mb={1}>
          {renderData(item, disabled, `${path}/${index}`)}
        </Box>
      ))
    } else {
      return (
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          value={data}
          disabled={disabled}
          //onChange={e => onChange(path, e.target.value)}
          style={{ marginBottom: '16px' }}
        />
      )
    }
  }

  return (
    <>
      <div
        style={onlyOpenform ? { padding: '5px', border: 'solid 1px red' } : {}}
      >
        <h3 style={onlyOpenform ? { color: 'red' } : {}}>
          {labelTitle + translate(label)}
        </h3>
        <Box>{renderData(value, disabled)}</Box>
      </div>
    </>
  )
}

JsonDataType.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]),
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  compareValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setAdditional: PropTypes.func,
  setError: PropTypes.func.isRequired,
  syncWithAdditional: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onlyOpenform: PropTypes.bool,
}
