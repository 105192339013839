export const addCustomFilters = (custom, current, parameters = []) => {
  const existable = custom.filter(filter =>
    parameters.find(param => param.name === filter.name)
  )

  return {
    ...current,
    ...Object.assign(
      {},
      ...existable.map(filter => {
        const param = parameters.find(param => param.name === filter.name)
        return {
          [filter.name]: {
            title: filter.title,
            name: filter.name,
            in: param.in,
            type: filter.Filter || param.type,
            value: filter.value === undefined ? null : filter.value,
            endpoint: filter?.endpoint,
          },
        }
      })
    ),
  }
}
