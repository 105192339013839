import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  imported: {
    color: theme.palette.info.main,
  },
}))

export const ImportableCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  const classes = useStyles()

  return (
    <div
      className={resource.importId ? classes.imported : null}
      onClick={onClick}
    >
      {prop(resource, accessor)}
    </div>
  )
}
