import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Form } from 'pages'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { Sidebar } from './sidebar'
import schema from '_schema/adminGroup'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const profile = useSelector(state => state.profile)
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return profile.group?.uuid === match.params.id ? (
    <Redirect to={routes().index.path} />
  ) : (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={properties =>
        addOpenformProperties(profile.group?.isOpenform, properties)
      }
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      SidebarComponent={Sidebar}
    />
  )
}
