const schema = {
  endpoint: '/api/config',
  access: 'config',
  resource: {
    definition: '#Config',
    properties: {
      freshApiKey: {
        type: 'string',
        label: '[FRESHMAIL] Key',
      },
      freshApiSecret: {
        type: 'string',
        label: '[FRESHMAIL] Secret',
      },
    },
  },
}

export default schema
