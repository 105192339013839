import formsetSchema from '_schema/formset'
import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const formset = () => ({
  name: BLOCK_TYPES_IRI.FORMSET,
  label: 'Formularz',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {
          type: FIELD_TYPES.STRING,
          label: 'Tytuł formularza',
        },
      },
    },
    formset: {
      type: FIELD_TYPES.RESOURCE,
      description: 'Formularz',
      endpoint: `${formsetSchema.endpoint}?pagination=false`,
      titleAccessor: `translations.${
        LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
      }.title`,
    },
  },
})
