import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const archiveEvents = () => ({
  name: BLOCK_TYPES_IRI.ARCHIVE_EVENTS,
  label: 'Archiwum wydarzeń MSN',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
        text: {
          type: FIELD_TYPES.TEXTAREA,
          description: 'Opis',
        }
      },
    },
  },
})
