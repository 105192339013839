import React, { useState } from 'react'
import clsx from 'clsx'
import { Dialog, Grid, Typography, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import CloseIcon from '@material-ui/icons/Close'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import { IconButton } from '@material-ui/core'
import { FileManager } from './FileManager'

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
  },
  flex_column: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper_md: {
    minHeight: '60vh',
  },
  paper: {
    maxWidth: '1000px',
  },
})

export const FileManagerModal = ({
  handleChoice,
  definitions,
  restrictMimeType,
  disabled
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  const toggleFullScreen = () => {
    setIsFullScreen(state => !state)
  }

  const handleToggle = () => {
    setIsOpen(state => !state)
  }

  const handleManagerChoice = (media) => {
    handleChoice(media)
    handleToggle()
  }

  const classes = useStyles()

  return (
    <>
      <IconButton
        color="primary"
        size="medium"
        variant="outlined"
        onClick={handleToggle}
        disabled={disabled}
      >
        <PermMediaIcon />
      </IconButton>
      <Dialog
        open={isOpen}
        fullScreen={isFullScreen}
        onClose={handleToggle}
        maxWidth="md"
        fullWidth={true}
        classes={{
          paper: clsx(
            classes.flex_column,
            classes.paper_md,
            !isFullScreen ? classes.paper : null
          ),
        }}
      >
        <DialogTitle container="div">
          <Grid item xs={12} container alignItems="center" direction="row">
            <Grid item xs={6}>
              <Typography className={classes.title}>
                File manager
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={6}
              wrap="nowrap"
              justifyContent="flex-end"
              alignItems="center"
              direction="row"
            >
              <IconButton onClick={toggleFullScreen}>
                <FullscreenIcon />
              </IconButton>
              <IconButton onClick={handleToggle}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <FileManager
          definitions={definitions}
          handleManagerChoice={handleManagerChoice}
          restrictMimeType={restrictMimeType}
        />
      </Dialog>
    </>
  )
}
