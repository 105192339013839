import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const newestNews = () => ({
  name: BLOCK_TYPES_IRI.NEWEST_NEWS,
  label: 'Aktualności',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
  },
})
