import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import _block from './_block'
import mediaSchema from './media'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/cinema_cycles',
  access: 'cinemaCycle',
  resource: {
    definition: '#CinemaCycle',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/kino/cykle/:slug',
              [LANGS_IRI.EN]: '/cinema/cycles/:slug',
            },
          },
        },
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: mediaSchema.endpoint,
        thumbs: {
          endpoint: '/api/cinema_cycle_thumbs',
        },
        validate: ['maxSize'],
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/cinema_cycle_blocks',
      definition: '#CinemaCycleBlock',
      types: [..._block('cinema_cycle').types],
    },
  },
}

export default schema
