import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  TableContainer,
  Toolbar,
  Typography,
  Breadcrumbs,
  Table,
  TableFooter,
  TableRow,
  TablePagination
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import FolderIcon from '@material-ui/icons/FolderOpenOutlined'
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined'
import ReorderOutlinedIcon from '@material-ui/icons/ReorderOutlined'
import UploadIcon from '@material-ui/icons/Backup'
import { Tree } from './Tree'
import { CreateDirModal } from './CreateDirModal'
import { SortableTableHead } from './SortableTableHead'
import { GridView } from './GridView'
import { ListView } from './ListView'
import { Uploader } from './Uploader'
import { fetchMedia } from './_helpers/fetchMedia'

const useStyles = makeStyles(theme => ({
  container: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  table_container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}))

export const FileManager = ({
  definitions,
  restrictMimeType = null,
  handleManagerChoice = null
}) => {
  const properties = {
    originalName: 'Nazwa',
    size: 'Rozmiar',
    mimeType: 'Typ',
    createdAt: 'Data'
  }

  /** DIR TREE */

  const [treeRefresh, setTreeRefresh] = useState(false)

  const handleTreeRefresh = () => {
    setTreeRefresh(state => !state)
  }

  /** CURRENT DIR */

  const [currentDir, setCurrentDir] = useState('/')

  const handleCurrentDir = (dir) => {
    setCurrentDir(dir)
    setIsUploaderMode(false)
    setCurrentDirMediaPage(0)
  }

  /** CURRENT DIR MEDIAS ORDER */

  const [currentDirMediaOrder, setCurrentDirMediaOrder] = useState(
    { property: 'originalName', direction: 'asc' }
  )

  const handleSortChoose = (e) => {
    const target = e.target.getAttribute('order-property')
      ? e.target
      : e.target.closest(['[order-property]'])

    const property = target.getAttribute('order-property')
    const direction = target.getAttribute('order-direction')

    setCurrentDirMediaOrder({ property, direction })
  }

  /** CURRENT DIR MEDIAS */

  const [currentDirMedia, setCurrentDirMedia] = useState({ items: [], count: null })
  const [currentDirMediaPage, setCurrentDirMediaPage] = useState(0)
  const [currentDirMediaPerPage, setCurrentDirMediaPerPage] = useState(20)

  const handleCurrentDirMediaPage = (e, newPage) => {
    setCurrentDirMediaPage(newPage)
  }

  const handleCurrentDirMediaPerPage = (e) => {
    setCurrentDirMediaPerPage(parseInt(e.target.value), 10)
    setCurrentDirMediaPage(0)
  }

  const handleModifyCurrentDirMedia = (resource) => {
    setCurrentDirMedia(state => ({
      ...state,
      items: state.items.map(
        (item) => item['@id'] === resource['@id']
          ? resource
          : item
      )
    }))
  }

  const [isCurrentDirMediaFetching, setIsCurrentDirMediaFetching] = useState(false)
  const [refreshCurrentDirMedia, setRefreshCurrentDirMedia] = useState(false)

  const handleCurrentDirMediaRefresh = () => {
    setRefreshCurrentDirMedia(state => !state)
    setCurrentDirMediaPage(0)
  }

  useEffect(() => {
    fetchMedia(
      currentDir,
      currentDirMediaPage + 1,
      currentDirMediaPerPage,
      currentDirMediaOrder,
      setCurrentDirMedia,
      setIsCurrentDirMediaFetching,
      restrictMimeType
    )
  }, [
    currentDir,
    currentDirMediaPage,
    currentDirMediaPerPage,
    refreshCurrentDirMedia,
    currentDirMediaOrder,
    restrictMimeType
  ])

  /** BREADCRUMBS */

  const handleBreacrumbs = (e) => {
    const target = e.target.getAttribute('index')
      ? e.target
      : e.target.closest('[index]')

    const index = target.getAttribute('index')

    setCurrentDir(getCompletePath(currentDir, index))
    setIsUploaderMode(false)
  }

  /** VIEW: GRID/LIST */

  const [isGridView, setIsGridView] = useState(true)

  const handleGridView = () => {
    setIsGridView(state => !state)
    setIsUploaderMode(false)
  }

  /** UPLOAD DIALOG INSTEAD OF MEDIAS */

  const [isUploaderMode, setIsUploaderMode] = useState(false)

  const handleUploaderModeToggle = () => {
    setIsUploaderMode(state => !state)
  }

  const classes = useStyles()

  return (
    <>
      <AppBar
        position="static"
        style={{ color: 'black', background: 'none' }}
      >
        <Toolbar>
          <CreateDirModal
            edge="start"
            color="inherit"
            currentDir={currentDir}
            callback={handleTreeRefresh}
          />
          <div style={{ width: 20 }}></div>
          <Button
            edge="start"
            color="inherit"
            onClick={handleUploaderModeToggle}
          >
            {isUploaderMode ? (
                <>
                  <ReorderOutlinedIcon />
                  <Typography variant="h6" noWrap style={{ marginLeft: 6 }}>
                    Wyświetl listę
                  </Typography>
                </>
              ) : (
                <>
                  <UploadIcon />
                  <Typography variant="h6" noWrap style={{ marginLeft: 6 }}>
                    Dodaj pliki
                  </Typography>
                </>
              )}
          </Button>
        </Toolbar>
      </AppBar>

      <Grid container>
        <Tree
          treeRefresh={treeRefresh}
          handleTreeRefresh={handleTreeRefresh}
          handleCurrentDir={handleCurrentDir}
        />
        <Grid item xs={9}>
          <Toolbar style={{ display: 'flex', paddingLeft: 0 }}>
            <Breadcrumbs
              aria-label="breadcrumbs"
              maxItems={4}
              itemsAfterCollapse={3}
              itemsBeforeCollapse={1}
            >
              <FolderIcon
                style={{ marginRight: '10px', cursor: 'pointer' }}
                onClick={() => handleCurrentDir('/')}
              />
              {splitCurrentDir(currentDir)
                .map((dir, index) => (
                  <div key={`breadcrumb-${index}`}>
                    <Button
                      index={index}
                      onClick={handleBreacrumbs}
                    >
                      {dir}
                    </Button>
                  </div>
                ))}
            </Breadcrumbs>
            <IconButton
              style={{ position: 'absolute', right: '10px' }}
              onClick={handleGridView}
            >
              {isGridView ? <ReorderOutlinedIcon /> : <AppsOutlinedIcon />}
            </IconButton>
          </Toolbar>
          <div className={classes.container}>
            {isUploaderMode ? (
              <Uploader
                currentDir={currentDir}
                callback={handleCurrentDirMediaRefresh}
              />
            ) : (
              <TableContainer className={classes.table_container}>
                <SortableTableHead
                  properties={properties}
                  order={currentDirMediaOrder}
                  onSortChoose={handleSortChoose}
                />
                {isGridView ? (
                  <GridView
                    isFetching={isCurrentDirMediaFetching}
                    handleDelete={handleCurrentDirMediaRefresh}
                    medias={currentDirMedia.items}
                    definitions={definitions}
                    handleModifyCurrentDirMedia={handleModifyCurrentDirMedia}
                    handleManagerChoice={handleManagerChoice}
                  />
                ) : (
                  <ListView
                    isFetching={isCurrentDirMediaFetching}
                    handleDelete={handleCurrentDirMediaRefresh}
                    medias={currentDirMedia.items}
                    definitions={definitions}
                    handleModifyCurrentDirMedia={handleModifyCurrentDirMedia}
                    handleManagerChoice={handleManagerChoice}
                  />
                )}
                {currentDirMedia.count > 0 && (
                  <Table className={classes.table}>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          labelRowsPerPage="Pokaż"
                          rowsPerPageOptions={[
                            ...[20, 35, 50],
                            ...(![20, 35, 50].includes(currentDirMedia.count)
                              ? [{ label: 'Wszystkie', value: currentDirMedia.count }]
                              : []
                            ),
                          ]}
                          colSpan={3}
                          count={currentDirMedia.count}
                          page={currentDirMediaPage}
                          rowsPerPage={currentDirMediaPerPage}
                          onPageChange={handleCurrentDirMediaPage}
                          onRowsPerPageChange={handleCurrentDirMediaPerPage}
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>)}
              </TableContainer>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

const splitCurrentDir = (currentDir) => currentDir === '/' ? [] : currentDir.split('/').slice(1)

const getCompletePath = (full, index) => {
  const dirs = splitCurrentDir(full)

  let complete = ''

  for (let i = 0; i <= index; i++) {
    complete = `${complete}/${dirs[i]}`
  }

  return complete
}
