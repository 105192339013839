import {
  PeopleOutlined,
  DescriptionOutlined,
  SettingsOutlined,
  EventOutlined,
  MovieOutlined,
  MenuBookOutlined,
  ArchiveOutlined,
} from '@material-ui/icons'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import home from 'pages/Home/routes'
import admins from 'pages/Admins/routes'
import adminGroups from 'pages/AdminGroups/routes'
import loginEntries from 'pages/LoginEntries/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import settings from 'pages/Settings/routes'
import profile from 'pages/Profile/routes'
import pages from 'pages/Pages/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import config from 'pages/Config/routes'
import artists from 'pages/Artists/routes'
import exhibits from 'pages/Exhibits/routes'
import exhibitions from 'pages/Exhibitions/routes'
import collections from 'pages/Collections/routes'
import events from 'pages/Events/routes'
import eventTypes from 'pages/EventTypes/routes'
import news from 'pages/News/routes'
import footerMenuItems from 'pages/FooterMenuItems/routes'
import homeSlides from 'pages/HomeSlides/routes'
import branches from 'pages/Branches/routes'
import importEntries from 'pages/ImportEntries/routes'
import resources from 'pages/Resources/routes'
import magazines from 'pages/Magazines/routes'
import formsets from 'pages/Formsets/routes'
import managerMedia from 'pages/ManagerMedia/routes'
import cinemaEvents from 'pages/CinemaEvents/routes'
import cinemaCycles from 'pages/CinemaCycles/routes'
import publishingHouseAuthors from 'pages/PublishingHouseAuthors/routes'
import publishingHouseSeries from 'pages/PublishingHouseSeries/routes'
import publishingHousePublications from 'pages/PublishingHousePublications/routes'
import newsletterCategory from 'pages/NewsletterCategory/routes'
import newsletterConfig from 'pages/ConfigNewsletter/routes'
import jobs from 'pages/Job/routes'
import archiveArticles from 'pages/ArchiveArticles/routes'
import archiveCategories from 'pages/ArchiveCategories/routes'
import archiveCycles from 'pages/ArchiveCycles/routes'

const menu = () => [
  home().index,
  homeSlides().index,
  pages().index,
  artists().index,
  exhibits().index,
  exhibitions().index,
  {
    title: 'Wydarzenia',
    icon: EventOutlined,
    items: [events().index, eventTypes().index],
  },
  collections().index,
  resources().index,
  news().index,
  footerMenuItems().index,
  branches().index,
  jobs().index,
  magazines().index,
  formsets().index,
  importEntries().index,
  managerMedia().index,
  {
    title: 'Kino',
    icon: MovieOutlined,
    items: [cinemaEvents().index, cinemaCycles().index],
  },
  {
    title: 'Wydawnictwo',
    icon: MenuBookOutlined,
    items: [
      publishingHouseAuthors().index,
      publishingHouseSeries().index,
      publishingHousePublications().index,
    ],
  },
  {
    title: 'Archiwa MSN',
    icon: ArchiveOutlined,
    items: [
      archiveArticles().index,
      archiveCategories().index,
      archiveCycles().index,
    ],
  },
  {
    title: 'Newsletter',
    icon: MailOutlineIcon,
    items: [newsletterCategory().index, newsletterConfig().edit],
  },
  {
    title: 'Administrators',
    icon: PeopleOutlined,
    items: [admins().index, adminGroups().index],
  },
  {
    title: 'T_MENU_REGISTRIES',
    icon: DescriptionOutlined,
    items: [registryEntries().index, loginEntries().index],
  },
  {
    title: 'T_MODULE_SETTINGS',
    icon: SettingsOutlined,
    items: [settings().index, config().edit, profile().index],
  },
  translatorEntries().index,
]

export default menu
