import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/publishing_house_authors',
  access: 'cinemaCycle',
  resource: {
    definition: '#CinemaCycle',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {
            description: 'Imię i nazwisko',
          },
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/wydawnictwo/autorzy/:slug',
              [LANGS_IRI.EN]: '/publishing-house/authors/:slug',
            },
          },
        },
      },
    },
  },
}

export default schema
