import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import audioPlayerThemesSchema from '_schema/_enums/audioPlayerTheme'
import { LANGS_IRI } from '_lib/langs'

const THUMBS_ENDPOINT = '/api/<TYPE>_block_thumbs'

export const audioPlayer = type => ({
  name: BLOCK_TYPES_IRI.AUDIO_PLAYER,
  label: 'Audio player',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
        audioPlayerTranscript: {
          description: 'Transkrypt',
          type: FIELD_TYPES.TINYMCE,
        },
      },
    },
    media: {
      type: FIELD_TYPES.IMAGE,
      description: 'Obrazek',
      endpoint: mediaSchema.endpoint,
      thumbs: {
        endpoint: THUMBS_ENDPOINT.replace('<TYPE>', type),
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            title: media.translations[LANGS_IRI.PL].title,
          },
          [LANGS_IRI.EN]: {
            title: media.translations[LANGS_IRI.EN].title,
          },
        })
      },
    },
    audioPlayerFile: {
      type: FIELD_TYPES.FILE,
      description: 'Plik audio',
      endpoint: mediaSchema.endpoint,
      accept: 'audio/*',
      restrictManagerMimeType: 'audio/',
      validate: ['maxSize'],
    },
    // audioPlayerTheme: {
    //   description: 'Skórka',
    //   type: FIELD_TYPES.RESOURCE,
    //   titleAccessor: 'title',
    //   endpoint: audioPlayerThemesSchema.endpoint,
    //   validate: ['required'],
    // },
  },
})
