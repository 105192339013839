import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  TextField,
  Popper,
  Button,
  Collapse,
  Paper,
  Grid,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(() => ({
  form_control: {
    minWidth: 200,
    marginTop: 10,
  },
  form_control_full_width: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
}))

export const IksorisType = ({
  name,
  value,
  label,
  endpoint = null,
  resources = null,
  error = false,
  renderError = false,
  disabled = false,
  fullWidth = false,
  validators,
  setValue,
  titleAccessor,
}) => {
  const [id] = useState(randomHash())

  const [choices, setChoices] = useState(resources || [])
  const [isFetching, setFetching] = useState(true)

  const handleChange = (e, option) => {
    setValue(name, option ? option.value : 0)
  }

  const [show, setShow] = useState(false)

  const handleClick = () => {
    setShow(!show)
  }

  const compare = (a, b) => {
    return new Intl.Collator().compare(a[titleAccessor], b[titleAccessor])
  }

  useEffect(
    () => {
      if (!endpoint || resources) {
        return
      }

      const controller = new AbortController()
      const { signal } = controller

      if (show && choices.length === 0) {
        setFetching(true)

        fetchDataHandleAuthError(
          endpoint,
          'GET',
          { signal },
          response => {
            let data = response['hydra:member']

            setChoices(data?.sort(compare))
            setFetching(false)
          },
          error => {
            if (error.response.title === 'AbortError') {
              return
            }

            notification('error', error.response.detail, error.response.title)
          }
        )
      }

      return () => controller.abort()
    },
    // eslint-disable-next-line
    [resources, show]
  )

  const options = choices.map(record => ({
    value: record.id,
    title:
      typeof titleAccessor === 'string'
        ? prop(record, titleAccessor)
        : titleAccessor(record),
  }))

  const classes = useStyles()

  return (
    <div style={{ marginTop: 30 }}>
      <Button
        onClick={handleClick}
        variant="outlined"
        color="primary"
        size="small"
      >
        {show ? 'Ukryj wybór z Iksoris' : 'Pokaż wybór z Iksoris'}
      </Button>
      <br />
      {/* {show ? ( */}
      <Collapse in={show}>
        <Paper
          elevation={4}
          style={{ backgroundColor: '#e9ecf0', padding: '10px' }}
        >
          <div>Wybierz z Iksoris</div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isFetching ? (
                <Loader style={{ paddingTop: '10px' }} />
              ) : (
                <FormControl
                  className={clsx(
                    classes.from_control,
                    fullWidth && classes.from_control_full_width
                  )}
                  error={renderError && !!error}
                >
                  <Autocomplete
                    id={id}
                    name={name}
                    options={options}
                    getOptionLabel={option => option.title}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value
                    }}
                    onChange={handleChange}
                    value={
                      value
                        ? {
                            value: value,
                            title:
                              options.find(option => option.value === value)
                                ?.title || '',
                          }
                        : null
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          translate(label.text || label) +
                          (validators && validators.includes('required')
                            ? ' *'
                            : '')
                        }
                        variant="outlined"
                      />
                    )}
                    PopperComponent={props => (
                      <Popper
                        {...props}
                        style={{ width: 'auto' }}
                        placement="bottom-start"
                      />
                    )}
                    disabled={disabled || !choices.length}
                    classes={{ root: classes.autocomplete }}
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    </div>
  )
}
