import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import _block from './_block'
import mediaSchema from './media'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/archive_cycles',
  access: 'archiveCycle',
  resource: {
    definition: '#ArchiveCycle',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          subtitle: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          alt: {
            description: 'Opis alternatywny',
            type: FIELD_TYPES.STRING,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/archiwa-msn/cykl/:slug',
              [LANGS_IRI.EN]: '/msn-archives/cycle/:slug',
            },
          },
        },
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: mediaSchema.endpoint,
        thumbs: {
          endpoint: '/api/archive_cycle_thumbs',
        },
        validate: ['maxSize'],
      },
      importData: {
        type: FIELD_TYPES.JSON_DATA,
        onlyOpenform: true,
        disabled: true,
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/archive_cycle_blocks',
      definition: '#ArchiveCycleBlock',
      types: [..._block('archive_cycle').types],
    },
  },
}

export default schema
