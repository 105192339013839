import React, { useMemo } from 'react'
import clsx from 'clsx'
import { InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { EmbeddedCollection } from 'components/embedded'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 15,
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 5,
    verticalAlign: 'middle',
  },
  labelButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    marginBottom: 15,
  },
}))

export const EmbeddedCollectionType = ({
  uuid,
  label,
  properties,
  definitionRef = null,
  endpoint,
  formUrl,
  titleAccessor = null,
  disabled = false,
  definitions,
  parent,
  classes = {},
}) => {
  const schema = useSelector(state => state.schema)
  const definitionSchema = useMemo(
    () => (definitionRef ? schema.definitions[definitionRef] : schema),
    [schema, definitionRef]
  )

  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      <InputLabel className={clsx(defaultClasses.label, classes.label)}>
        <span style={label.color && { color: label.color }}>
          {translate(label.text || label)}
        </span>
      </InputLabel>
      <EmbeddedCollection
        endpoint={endpoint}
        parent={parent}
        parentIri={formUrl}
        properties={properties}
        definitionSchema={definitionSchema}
        alignButtonEvenly={true}
        titleAccessor={titleAccessor}
        disabled={!uuid || disabled}
        definitions={definitions}
      />
    </div>
  )
}
