import React, { useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  MenuItem as MUIMenuItem,
  Tooltip,
  Collapse,
  List,
  ListItemIcon,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ExpandMoreOutlined, Edit } from '@material-ui/icons'
import { MenuItem } from './MenuItem'
import { PageTree } from './PageTree'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  list: {
    paddingLeft: 18,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    backgroundColor: theme.elements.subMenu,
  },
  listSidebarClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  header: {
    color: theme.elements.menu.text,
    '& *': {
      color: theme.elements.menu.text,
    },
    '&:hover': {
      backgroundColor: theme.elements.menu.hover,
    },
    fontSize: '1.0em',
    paddingTop: 12,
    paddingBottom: 12,
  },
  headerListItemIcon: {
    minWidth: 44,
  },
  headerIcon: {
    fontSize: 22,
  },
  listItem: {
    fontSize: '.95em',
  },
  listItemIcon: {
    minWidth: 24,
    borderRadius: '50%',
    border: '1px solid',
    marginRight: '6px',
    marginLeft: '-17px',
  },
  icon: {
    fontSize: 24,
    padding: '5px',
  },
  pageTreeWrapper: {
    marginLeft: 0,
    marginTop: '-7px',
    paddingBottom: '7px',
    '& ul': { paddingLeft: '24px', listStyleType: 'none', margin: 0 },
    '& ul li': { position: 'relative' },
    '& ul li:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-11px',
      background: '#979797',
      top: '-4px',
      height: 'calc(100% + 11px)',
      width: '1px',
    },
    '& ul li:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-11px',
      background: '#979797',
      top: 'calc(0.6em + 11px)',
      height: '1px',
      width: '11px',
    },
    '& ul li:last-child:before': { height: 'calc(0.6em + 15px)' },
  },
}))

export const PageMenu = ({ item, endpoint, editPath, isSidebarOpen }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(state => !state)
  }

  const classes = useStyles()

  const IconComponent = item.icon

  const header = (
    <MUIMenuItem button onClick={handleToggle} className={classes.header}>
      <ListItemIcon className={classes.headerListItemIcon}>
        {isOpen ? (
          <ExpandMoreOutlined className={classes.headerIcon} />
        ) : (
          <IconComponent className={classes.headerIcon} />
        )}
      </ListItemIcon>
      {translate(item.title)}
    </MUIMenuItem>
  )

  const modifiedItem = useMemo(
    () => ({
      ...item,
      title: translate('T_MODULE_PAGES_EDIT_STRUCTURE'),
      icon: Edit,
    }),
    [item]
  )

  const [pages, setPages] = useState([])

  const pageMenuReload = useSelector(state => state.common.pageMenuReload)

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      `${endpoint}?order[ord]=desc&exists[parent]=false&pagination=false`,
      'GET',
      { signal },
      response => {
        setPages(response['hydra:member'])
      }
    )

    return () => controller.abort()
  }, [endpoint, setPages, pageMenuReload])

  return (
    <>
      {isSidebarOpen ? (
        header
      ) : (
        <Tooltip title={translate(item.title)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={clsx(
            classes.list,
            !isSidebarOpen && classes.listSidebarClosed
          )}
        >
          {isSidebarOpen ? (
            <MenuItem
              item={modifiedItem}
              exact={true}
              classes={{
                listItem: classes.listItem,
                listItemIcon: classes.listItemIcon,
                icon: classes.icon,
              }}
            />
          ) : (
            <Tooltip title={translate(item.title)} placement="right">
              <div>
                <MenuItem
                  item={modifiedItem}
                  exact={true}
                  classes={{
                    listItem: classes.listItem,
                    icon: classes.icon,
                  }}
                />
              </div>
            </Tooltip>
          )}
          {isSidebarOpen && pages.length ? (
            <div className={classes.pageTreeWrapper}>
              <ul>
                {pages.map(page => (
                  <PageTree item={page} editPath={editPath} key={page['@id']} />
                ))}
              </ul>
            </div>
          ) : null}
        </List>
      </Collapse>
    </>
  )
}

PageMenu.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    icon: PropTypes.elementType.isRequired,
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  editPath: PropTypes.string.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
}
