import React from 'react'
import { Route } from 'react-router-dom'
import { FaceOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { View } from './View'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/artist'

const PATH = '/artists'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: PATH,
        exact: true,
        icon: FaceOutlined,
      },
      view: {
        subtitle: 'T_GENERAL_RECORD_VIEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={View} {...props} />
        ),
        path: `${PATH}/:id`,
        exact: true,
      },
    },
    {
      title: 'Artyści',
      resourceAccess: schema.access,
      indexPath: PATH,
    }
  )

export default routes
