import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const cinemaAgenda = () => ({
  name: BLOCK_TYPES_IRI.CINEMA_AGENDA,
  label: 'Kino / Agenda',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
  },
})
