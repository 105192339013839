import React from 'react'
import { Form } from 'pages'
import { Sidebar } from 'components/resource/sidebar'
import schema from '_schema/cinemaEvent'
import routes from './routes'
import { ManualNextRevalidatorButton } from '../../components/ManualNextRevalidatorButton'
import { EmbeddedCollection } from '../../components/embedded'
import { LANGS_IRI } from '../../_lib/langs'
import { BlockCollection } from '../../components/block'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitions={definitions}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor="title"
      SidebarComponent={Sidebar}
      width={600}
      header={resource => (
        <ManualNextRevalidatorButton
          type="cinema_events"
          uuid={resource.uuid}
        />
      )}
    >
      <EmbeddedCollection
        endpoint={schema.subresources.images.endpoint}
        parentIri={iri}
        properties={schema.subresources.images.properties}
        definitions={definitions}
        definitionSchema={definitions[schema.subresources.images.definition]}
        statable={true}
        headerTitle="Zdjęcia"
        titleAccessor={`translations.${LANGS_IRI.PL}.title`}
        key={`${iri}-related-events`}
      />
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        parentIri={iri}
        definitions={definitions}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        statable={true}
        key={`${iri}-blocks`}
      />
    </Form>
  )
}
