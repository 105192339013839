import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import eventTypeSchema from '_schema/eventType'
import { eventHeader as eventHeaderBlock } from '_schema/_blocks/_eventHeader'
import { eventRelated as eventRelatedBlock } from '_schema/_blocks/_eventRelated'
import _block from './_block'
import mediaSchema from '_schema/media'

const ENDPOINT = '/api/events'

const schema = {
  endpoint: ENDPOINT,
  access: 'event',
  resource: {
    definition: '#Event',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          location: {
            type: FIELD_TYPES.TINYMCE,
          },
          price: {
            type: FIELD_TYPES.TINYMCE,
          },
          specializations: {
            type: FIELD_TYPES.TINYMCE,
          },
          duration: {
            type: FIELD_TYPES.TINYMCE,
          },
          age: {
            type: FIELD_TYPES.TINYMCE,
          },
          alt: {},
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/wydarzenia/:slug',
              [LANGS_IRI.EN]: '/en/events/:slug',
            },
          },
        },
      },
      type: {
        type: FIELD_TYPES.RESOURCE,
        titleAccessor: 'title',
        endpoint: `${eventTypeSchema.endpoint}?pagination=false`,
        validate: ['required'],
      },
      dateFrom: {
        type: 'date',
      },
      dateTo: {
        type: 'date',
      },
      timeFrom: {
        type: 'time',
      },
      timeTo: {
        type: 'time',
      },
      iksorisId: {
        type: FIELD_TYPES.IKSORIS,
        endpoint: '/api/iksoris/events',
        titleAccessor: 'name',
        label: 'Wydarzenie',
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: mediaSchema.endpoint,
        thumbs: {
          endpoint: '/api/event_thumbs',
        },
        validate: ['maxSize'],
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: { alt: media.translations[LANGS_IRI.PL].alt },
            [LANGS_IRI.EN]: { alt: media.translations[LANGS_IRI.EN].alt }
          })
        }
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/event_blocks',
      definition: '#EventBlock',
      types: [
        ..._block('event').types,
        eventHeaderBlock(),
        eventRelatedBlock(true, 'event'),
      ],
    },
    relatedEvents: {
      endpoint: '/api/event_related_events',
      definition: '#EventRelatedEvent',
      properties: {
        event: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: 'title',
          searchProperty: 'title',
          endpoint: ENDPOINT,
          validate: ['required'],
        },
      },
    },
  },
}

export default schema
