import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import exhibitSchema from '_schema/exhibit'
import { LANGS_IRI } from '_lib/langs'

export const exhibitionImageCarousel = (exhibition) => ({
  name: BLOCK_TYPES_IRI.EXHIBITION_IMAGE_CAROUSEL,
  label: 'Wystawa: karuzela zdjęć lub eksponatów',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    isImageTextHidden: {
      description: 'Opisy zdjęć ukryte',
      type: FIELD_TYPES.BOOLEAN,
    },
    images: {
      description: 'Zdjęcia',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: '/api/exhibition_block_images',
      titleAccessor: (resource) =>
        resource?.exhibit?.translations[LANGS_IRI.PL]?.title ||
        resource?.translations[LANGS_IRI.PL].title ||
        '<Brak eksponatu lub tytułu>',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              description: 'Tytuł',
              type: FIELD_TYPES.STRING,
            },
            subtitle: {
              description: 'Podpis',
              type: FIELD_TYPES.STRING,
            },
            text: {
              description: 'Opis',
              type: FIELD_TYPES.TINYMCE,
            },
            alt: {
              description: 'Opis alternatywny',
              type: FIELD_TYPES.STRING,
            },
          },
        },
        media: {
          type: FIELD_TYPES.IMAGE,
          description: 'Zdjęcie',
          endpoint: mediaSchema.endpoint,
          thumbs: {
            endpoint: '/api/exhibition_block_image_thumbs',
          },
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                title: media.translations[LANGS_IRI.PL].title,
                text: media.translations[LANGS_IRI.PL].description,
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              [LANGS_IRI.EN]: {
                title: media.translations[LANGS_IRI.EN].title,
                text: media.translations[LANGS_IRI.EN].description,
                alt: media.translations[LANGS_IRI.EN].alt,
              }
            })
          }
        },
        exhibit: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: (resource) => `${resource.title} (${resource.objectNumber})`,
          searchProperty: 'title',
          description: 'Eksponat',
          endpoint: exhibitSchema.endpoint,
          parent: exhibition,
          parentProperty: 'exhibitions.uuid',
        }
      },
    },
  },
})
