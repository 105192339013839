import { makeStyles } from '@material-ui/styles'

export const useStaticCollectionStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 25,
    marginTop: 10,
    borderBottom: `1px solid ${theme.elements.background}`,
  },
  massExpand: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  collectionContainer: {
    position: 'relative',
    overflowX: 'inherit',
  },
  cell: {
    verticalAlign: 'middle',
    borderBottom: 'none',
  },
  itemContainer: {
    display: 'block',
  },
}))
