import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import adminGroupSchema from './adminGroup'

const schema = {
  endpoint: '/api/admins',
  access: 'admin',
  resource: {
    definition: '#Admin',
    properties: {
      fullName: {
        validate: ['required', 'length(3)'],
      },
      email: {
        validate: ['required', 'email'],
      },
      rawPassword: {
        type: FIELD_TYPES.PASSWORD_REPEAT,
        description: 'T_FORM_FIELD_NEW_PASSWORD',
        hint: {
          origin: 'T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD',
          repeat: 'T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD',
        },
        validate: ['required', 'password'],
      },
      group: {
        type: FIELD_TYPES.RESOURCE,
        endpoint: `${adminGroupSchema.endpoint}?order[createdAt]=desc&pagination=false`,
        titleAccessor: 'title',
      },
    },
  },
}

export default schema
