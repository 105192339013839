import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core'
import { SearchOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { filters as filterComponents } from './filters'
import { FilterError } from './_helpers/filterError'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  search: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    marginRight: 15,
    marginBottom: 23,
  },
  disabled: {
    cursor: 'default',
    color: theme.palette.disabled,
    '&:hover': {
      color: theme.palette.disabled,
    },
  },
  cancelButton: {
    color: theme.palette.error.main,
  },
  confirmButton: {
    color: theme.palette.success.main,
  },
  filterWrapper: {
    marginBottom: 15,
  },
  dialogContent: {
    textAlign: 'center',
  },
}))

export const FilterBar = ({ filters, disabled = false, handleFilters }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(state => !state)
  }

  const [filterState, setFilterState] = useState(filters)

  const setFilter = (name, value) => {
    setFilterState(state => ({
      ...state,
      [name]: {
        ...state[name],
        value,
      },
    }))
  }

  const handleFilter = useCallback(
    (name, value) => {
      handleFilters({
        [name]: {
          value,
        },
      })
    },
    [handleFilters]
  )

  const handleSearch = () => {
    if (disabled) {
      return
    }

    handleToggle()
    handleFilters(filterState)
  }

  useEffect(() => {
    setFilterState(filters)
  }, [filters, setFilterState])

  return Object.keys(filterState).filter(key => filterState[key].title)
    .length ? (
    <>
      <div className={classes.root}>
        <Tooltip title={translate('T_GENERAL_SEARCH')}>
          <SearchOutlined
            onClick={handleToggle}
            className={clsx(classes.search, disabled && classes.disabled)}
          />
        </Tooltip>
      </div>
      <Dialog onClose={handleToggle} open={open}>
        <DialogTitle>{translate('T_GENERAL_SEARCH')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {Object.keys(filterState)
            .filter(key => filterState[key].title)
            .map(key => {
              if (
                typeof filterState[key].type === 'string' &&
                !filterComponents[filterState[key].type]
              ) {
                throw new FilterError(filterState[key].type)
              }

              const FilterComponent =
                typeof filterState[key].type === 'string'
                  ? filterComponents[filterState[key].type]
                  : filterState[key].type

              return (
                <div className={classes.filterWrapper} key={`filter-${key}`}>
                  <FilterComponent
                    name={key}
                    title={filterState[key].title}
                    value={filterState[key].value}
                    disabled={disabled}
                    handleEnter={handleSearch}
                    setFilter={setFilter}
                    handleFilter={handleFilter}
                    endpoint={filterState[key]?.endpoint}
                  />
                </div>
              )
            })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleToggle}
            className={classes.cancelButton}
            disabled={disabled}
            autoFocus
          >
            {translate('T_GENERAL_CANCEL')}
          </Button>
          <Button
            onClick={handleSearch}
            className={classes.confirmButton}
            disabled={disabled}
          >
            {translate('T_GENERAL_SEARCH')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null
}

FilterBar.propTypes = {
  filters: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
        .isRequired,
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    })
  ).isRequired,
  disabled: PropTypes.bool,
  handleFilters: PropTypes.func.isRequired,
}
