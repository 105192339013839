import React from 'react'
import { getResourceMediaUrl } from '_helpers/getResourceMediaUrl'

export const Media = ({ resource, isSmall = false }) => {
  const type = resource.type
  const url = getResourceMediaUrl(resource)

  return type === 'image' ? (
    <img
      src={url}
      width="300"
      alt="preview"
      style={isSmall ? {
        position: 'relative',
        width: '90%'
      } : {
        width: 300
      }}
    />
  ) : type === 'doc' ? (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      download
    >
      {isSmall ? 'Pobierz' : resource.description}
    </a>
  ) : (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      download
    >
      {isSmall ? 'Pobierz' : url}
    </a>
  )
}
