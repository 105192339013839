import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import { LANGS_IRI } from '_lib/langs'

const ITEMS_ENDPOINT = '/api/<TYPE>_block_images'
const ITEMS_THUMBS_ENDPOINT = '/api/<TYPE>_block_image_thumbs'

export const contentSlider = type => ({
  name: BLOCK_TYPES_IRI.CONTENT_SLIDER,
  label: 'Slajder',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    images: {
      description: 'Slajdy',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: ITEMS_ENDPOINT.replace('<TYPE>', type),
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              description: 'Tytuł',
              type: FIELD_TYPES.STRING,
            },
            text: {
              description: 'Opis',
              type: FIELD_TYPES.TINYMCE,
            },
            subtitle: {
              description: 'Podpis',
              type: FIELD_TYPES.STRING,
            },
            ctaTitle: {
              description: 'CTA: tytuł',
              type: FIELD_TYPES.STRING,
            },
            ctaLink: {
              description: 'CTA: link',
              type: FIELD_TYPES.STRING,
            },
            alt: {
              description: 'Opis alternatywny',
              type: FIELD_TYPES.STRING,
            },
          },
        },
        media: {
          type: FIELD_TYPES.IMAGE,
          description: 'Zdjęcie',
          endpoint: mediaSchema.endpoint,
          thumbs: {
            endpoint: ITEMS_THUMBS_ENDPOINT.replace('<TYPE>', type),
          },
          validate: ['maxSize'],
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
            })
          }
        },
      },
    },
  },
})
