import buildUrl from 'build-url'
import { handleResponse } from './handleResponse'
import { notification } from './notification'

const PATH = '/api/revalidate/:tag'

export const performTagRevalidation = tag => {
  const url = buildUrl(
    `${process.env.REACT_APP_NEXT_ENTRYPOINT}${PATH}`.replace(':tag', tag),
    {
      queryParams: {
        secret: process.env.REACT_APP_NEXT_REVALIDATE_SECRET,
      },
    }
  )

  fetch(url, { method: 'POST' })
    .then(handleResponse)
    .then(
      () => {},
      error => {
        if (error?.response?.title === 'AbortError') {
          return
        }

        notification('error', 'Tag revalidation failed', 'Failed')
      }
    )
}
