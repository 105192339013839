import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { darken } from '@material-ui/core'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  warning: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.1),
    },
  },
}))

export const WarningButton = ({ children, onClick, isWarning }) => {
  const classes = useStyles()

  return (
    <Button
      className={isWarning ? classes.warning : null}
      onClick={onClick}
      color="default"
      variant="contained"
    >
      {children}
    </Button>
  )
}
