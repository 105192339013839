import React from 'react'
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useViewCollectionFetch } from './_helpers/useViewCollectionFetch'
import { useStaticCollectionStyles } from 'components/static'
import { reducer } from './_state'

const useStyles = makeStyles({
  cell: {
    border: 'none',
  },
  loader: {
    marginBottom: 10,
    width: 200,
    fontSize: 13,
    marginLeft: 15,
    color: 'gray',
  },
})

export const ViewCollection = ({ endpoint, parentIri, render }) => {
  const [state] = useViewCollectionFetch(reducer, endpoint, parentIri)

  const defaultClasses = useStaticCollectionStyles()
  const classes = useStyles()

  return (
    <div>
      {state.isFetching && (
        <div className={classes.loader}>Ładuję rekordy...</div>
      )}
      <TableContainer className={defaultClasses.collectionContainer}>
        <Table size="small">
          <TableBody>
            {state.items.map((item, i) => (
              <TableRow key={`${item.uuid}`}>
                <TableCell key="item" className={classes.cell}>
                  {render(item)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
