import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import mediaSchema from '_schema/media'
import { LANGS_IRI } from '_lib/langs'

export const videoPlayer = () => ({
  name: BLOCK_TYPES_IRI.VIDEO_PLAYER,
  label: 'Video player',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
        vimeoId: {
          type: FIELD_TYPES.STRING,
          description: 'Id filmu w serwisie Vimeo',
        },
        youtubeId: {
          type: FIELD_TYPES.STRING,
          description: 'Id filmu w serwisie Youtube',
        },
        youtubeChannelId: {
          type: FIELD_TYPES.STRING,
          description: 'Channel id transmisji w serwisie Youtube',
        },
      },
    },
    videoPlayerFile: {
      type: FIELD_TYPES.FILE,
      description: 'Plik video',
      endpoint: mediaSchema.endpoint,
      accept: 'video/*',
      restrictManagerMimeType: 'video/',
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            title: media.translations[LANGS_IRI.PL].title,
          },
          [LANGS_IRI.EN]: {
            title: media.translations[LANGS_IRI.EN].title,
          },
        })
      },
    },
  },
})
