import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import { LANGS_IRI } from '../../_lib/langs'
import mediaSchema from '../media'

const CARDS_ENDPOINT = '/api/<TYPE>_block_cards'
const CARDS_THUMBS_ENDPOINT = '/api/<TYPE>_block_card_thumbs'

export const team = type => ({
  name: BLOCK_TYPES_IRI.TEAM,
  label: 'Zespół',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    cards: {
      description: 'Osoby',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: CARDS_ENDPOINT.replace('<TYPE>', type),
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              description: 'Imię i nazwisko',
              type: FIELD_TYPES.STRING,
            },
            subtitle: {
              description: 'Pozycja',
              type: FIELD_TYPES.STRING,
            },
            text: {
              description: 'Biografia',
              type: FIELD_TYPES.TEXTAREA,
            },
          },
        },
        phone: {
          type: FIELD_TYPES.STRING,
          description: 'Telefon',
        },
        email: {
          type: FIELD_TYPES.STRING,
          description: 'Email',
        },
        media: {
          type: FIELD_TYPES.IMAGE,
          description: 'Zdjęcie',
          endpoint: mediaSchema.endpoint,
          thumbs: {
            endpoint: CARDS_THUMBS_ENDPOINT.replace('<TYPE>', type),
          },
          validate: ['maxSize'],
        },
      },
    },
  },
})
