import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const LINKS_ENDPOINT = '/api/<TYPE>_block_related_links'
const ENDPOINT = '/api/jobs'

export const jobOffers = type => ({
  name: BLOCK_TYPES_IRI.JOB_OFFERS,
  label: 'Oferty pracy',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
        text: {
          type: FIELD_TYPES.TEXTAREA,
          description: 'Opis',
        },
      },
    },
    relatedLinks: {
      description: 'Oferty pracy',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: LINKS_ENDPOINT.replace('<TYPE>', type),
      titleAccessor: function(item) {
        if (item.job && item.job?.translations?.[LANGS_IRI.PL]?.title) {
          return item.job?.translations?.[LANGS_IRI.PL]?.title
        }
        return '--'
      },
      properties: {
        job: {
          label: 'Oferta pracy',
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: 'title',
          searchProperty: 'title',
          endpoint: ENDPOINT,
          validate: ['required'],
        },
      },
    },
  },
})
