import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from './table/CollectionTable'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/page'
import routes from './routes'

export const Collection = ({ wrapWithPaper = true, showNewButton = true }) => {
  const profile = useSelector(state => state.profile)

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  return (
    <WrapComponent>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_PAGES_NEW_BUTTON')}
        buttonPath={routes().new.path}
        hideButton={!showNewButton}
      >
        {translate('T_MODULE_PAGES')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        customResourceSchema={schema.resource}
        defaultFilters={{ 'exists[parent]': false, pagination: false }}
        defaultSorters={{ ord: 'desc' }}
        selectable={[publish.on(), publish.off()]}
        isRowSelectable={resource =>
          profile.group?.isOpenform || resource.isStatable
        }
        editPath={routes().edit.path}
        autoWidth={false}
        parameters={[
          {
            name: 'exists[parent]',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'pagination',
            in: 'query',
            required: false,
            type: 'boolean',
          },
        ]}
      />
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
