import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { prop } from '_helpers/prop'
import { buildUrl } from 'build-url'
import { Loader } from 'components/Loader'

const useStyles = makeStyles({
  header: {
    fontSize: 14,
    marginBottom: 10,
  },
  chosen: {
    color: 'gray',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
  },
  choices: {
    marginTop: 10,
    '&>*:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
})

export const AutocompleteType = ({
  name,
  label,
  titleProperty = 'title',
  searchProperty = 'title',
  hint = null,
  endpoint,
  initialValue,
  value,
  form,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  parent = null,
  parentProperty = null,
  generateEndpointFilters = null
}) => {
  const [text, setText] = useState('')
  const [choices, setChoices] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const handleTextChange = e => {
    const value = e.target.value
    setText(value)
  }

  const handleTextKeyUp = e => {
    const value = e.target.value

    if (value.length < 3) {
      setChoices([])

      return
    }

    const controller = new AbortController()
    const { signal } = controller

    const url = buildUrl(endpoint, {
      queryParams: {
        [searchProperty]: text,
        ...(parentProperty ? { [parentProperty]: parent } : {}),
        ...(generateEndpointFilters ? generateEndpointFilters(form, parent) : {})
      },
    })

    setIsFetching(true)

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        setIsFetching(false)
        setChoices(
          response['hydra:member'].length > 0
            ? response['hydra:member']
            : null
        )
      },
      error => {
        setIsFetching(false)

        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleChoose = e => {
    const uuid = e.target.getAttribute('uuid')
    const value = choices?.find(choice => choice.uuid === uuid)

    setValue(name, value)
    validateField(value)

    setText('')
    setChoices([])
  }

  const handleReset = () => {
    setValue(name, null)
    validateField(null)

    setText('')
    setChoices([])
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  return (
    <>
      <div className={classes.header}>
        {translate(label?.text || label) +
          (validators && validators.includes('required') ? ' *' : '')}
      </div>
      <div className={classes.chosen}>
        WYBRANY: {renderTitle(value, titleProperty)} {!validators?.includes('required') && value && (
          <CloseOutlined onClick={handleReset} style={{ cursor: 'pointer', marginLeft: 6 }} />
        )}
      </div>
      <TextField
        value={text}
        onChange={handleTextChange}
        onKeyUp={handleTextKeyUp}
        disabled={disabled}
        error={renderError && !!error}
        helperText={translate(renderError && error ? error : hint)}
        InputLabelProps={
          label?.color && {
            style: {
              color: label.color,
            },
          }
        }
        placeholder="Wpisz, aby zobaczyć podpowiedzi (min. 3 znaki) i wybrać"
        variant="standard"
        style={{
          width: 400,
        }}
      />
      <div className={classes.choices}>
        {isFetching && <Loader />}
        {choices?.map(choice => (
            <div uuid={choice.uuid} onClick={handleChoose} key={choice.uuid}>
              {renderTitle(choice, titleProperty)}
            </div>
        ))}
      </div>
      {text && choices === null && (
        <div>Brak wyników</div>
      )}
    </>
  )
}

const renderTitle = (value, titleProperty) => {
  if (!value) {
    return null
  }

  if (typeof titleProperty === 'string') {
    return prop(value, titleProperty)
  }

  return titleProperty(value)
}
