/** importable */
const schema = {
  endpoint: '/api/resources',
  endpoint_get_media: '/api/open/resources/:uuid/get_media',
  access: 'resource',
  resource: {
    definition: '#Resource',
  },
}

export default schema
