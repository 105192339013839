import React from 'react'
import { Route } from 'react-router-dom'
import { EventOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/cinemaEvent'
import { ScheduleCollection } from './ScheduleCollection'
// import { ScheduleEdit } from './ScheduleEdit'
// import { ScheduleNew } from './ScheduleNew'

const PATH = '/cinema_events'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: PATH,
        exact: true,
        icon: EventOutlined,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${PATH}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${PATH}/:id`,
        exact: true,
      },
      schedules: {
        subtitle: 'Harmonogram',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ScheduleCollection}
            {...props}
          />
        ),
        path: `${PATH}/:id/schedules`,
        exact: true,
      },
      // scheduleEdit: {
      //   subtitle: 'T_GENERAL_RECORD_EDIT',
      //   type: Route,
      //   render: props => (
      //     <SchemableComponent
      //       path="definitions"
      //       component={ScheduleEdit}
      //       {...props}
      //     />
      //   ),
      //   path: `${PATH}/schedules/:id`,
      //   exact: true,
      // },
      // scheduleNew: {
      //   subtitle: 'T_GENERAL_RECORD_NEW',
      //   type: Route,
      //   render: props => (
      //     <SchemableComponent
      //       path="definitions"
      //       component={ScheduleNew}
      //       {...props}
      //     />
      //   ),
      //   path: `${PATH}/:pid/schedules/new`,
      //   exact: true,
      // },
    },
    {
      title: 'Wydarzenia',
      resourceAccess: schema.access,
      indexPath: PATH,
    }
  )

export default routes
