import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'

const useStyles = makeStyles({
  image: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    width: 200,
  },
  img: {
    maxHeight: 100,
    width: '100%',
    height: 'auto',
  },
  title: {
    marginLeft: '1.0em',
  },
})

export const MainCellSave = ({
  resource,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  const classes = useStyles()

  return (
    <div onClick={onClick} className={classes.image}>
      <div className={classes.title}>
        {moment(resource.createdAt).format('DD.MM.YYYY HH:mm')}
      </div>
    </div>
  )
}
