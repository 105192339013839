export const BLOCK_TYPES = {
  '/api/block_types/TEXT': 'text',
  '/api/block_types/TEXT_WITH_PHOTO': 'text_with_photo',
  '/api/block_types/PHOTO': 'photo',
  '/api/block_types/GLOSSARY': 'glossary',
  '/api/block_types/NEWEST_NEWS': 'newest_news',
  '/api/block_types/CALENDAR': 'calendar',
  '/api/block_types/CURRENT_EXHIBITIONS': 'current_exhibitions',
  '/api/block_types/AUDIO_PLAYER': 'audio_player',
  '/api/block_types/VIDEO_PLAYER': 'video_player',
  '/api/block_types/ACCORDIONS': 'accordions',
  '/api/block_types/CARDS': 'cards',
  '/api/block_types/FILES': 'files',
  '/api/block_types/RELATED_LINKS': 'related_links',
  '/api/block_types/IMAGE_CAROUSEL': 'image_carousel',
  '/api/block_types/IMAGE_GALLERY': 'image_gallery',
  '/api/block_types/IMAGES': 'images',
  '/api/block_types/LOGO_GROUPS': 'logo_groups',
  '/api/block_types/CONTENT_MODULES': 'content_modules',
  '/api/block_types/CONTENT_SLIDER': 'content_slider',
  '/api/block_types/TEXT_ENTRIES': 'text_entries',
  '/api/block_types/BLOG_ENTRIES': 'blog_entries',
  '/api/block_types/MAGAZINE_ARTICLES': 'magazine_articles',
  '/api/block_types/MAGAZINE_TWO_ARTICLES': 'magazine_two_articles',
  '/api/block_types/MUSEUM_BRANCHES': 'museum_branches',
  '/api/block_types/EVENT_HEADER': 'event_header',
  '/api/block_types/EVENT_RELATED': 'event_related',
  '/api/block_types/EVENT_RELATED_PAGE': 'event_related_page',
  '/api/block_types/EXHIBITION_RELATED_PUBLICATIONS':
    'exhibition_related_publications',
  '/api/block_types/EXHIBITION_RELATED_EVENTS': 'exhibition_related_events',
  '/api/block_types/EXHIBITION_RELATED_EXHIBITIONS':
    'exhibition_related_exhibitions',
  '/api/block_types/EXHIBITION_EXHIBITS': 'exhibition_exhibits',
  '/api/block_types/EXHIBITION_ARTISTS': 'exhibition_artists',
  '/api/block_types/EXHIBITION_IMAGES': 'exhibition_images',
  '/api/block_types/EXHIBITION_IMAGE_CAROUSEL': 'exhibition_image_carousel',
  '/api/block_types/EXHIBITION_IMAGE_GALLERY': 'exhibition_image_gallery',
  '/api/block_types/EXHIBITION_SINGLE_EXHIBIT': 'exhibition_single_exhibit',
  '/api/block_types/FORMSET': 'formset',
  '/api/block_types/NEWSLETTER': 'newsletter',
  '/api/block_types/COLLECTION_PREVIEW': 'collection_preview',
  '/api/block_types/AUTHORS': 'authors',
  '/api/block_types/RELATED_NEWS': 'related_news',
}

export const BLOCK_TYPES_IRI = {
  TEXT: '/api/block_types/TEXT',
  TEXT_WITH_PHOTO: '/api/block_types/TEXT_WITH_PHOTO',
  PHOTO: '/api/block_types/PHOTO',

  NEWEST_NEWS: '/api/block_types/NEWEST_NEWS',
  CALENDAR: '/api/block_types/CALENDAR',
  CURRENT_EXHIBITIONS: '/api/block_types/CURRENT_EXHIBITIONS',
  BLOG_ENTRIES: '/api/block_types/BLOG_ENTRIES',
  MAGAZINE_ARTICLES: '/api/block_types/MAGAZINE_ARTICLES',
  MAGAZINE_TWO_ARTICLES: '/api/block_types/MAGAZINE_TWO_ARTICLES',

  MUSEUM_BRANCHES: '/api/block_types/MUSEUM_BRANCHES',

  AUDIO_PLAYER: '/api/block_types/AUDIO_PLAYER',
  VIDEO_PLAYER: '/api/block_types/VIDEO_PLAYER',

  LOGO_GROUPS: '/api/block_types/LOGO_GROUPS',
  CONTENT_MODULES: '/api/block_types/CONTENT_MODULES',
  CONTENT_SLIDER: '/api/block_types/CONTENT_SLIDER',
  TEXT_ENTRIES: '/api/block_types/TEXT_ENTRIES',
  GLOSSARY: '/api/block_types/GLOSSARY',
  ACCORDIONS: '/api/block_types/ACCORDIONS',
  CARDS: '/api/block_types/CARDS',
  FILES: '/api/block_types/FILES',
  RELATED_LINKS: '/api/block_types/RELATED_LINKS',

  IMAGE_CAROUSEL: '/api/block_types/IMAGE_CAROUSEL',
  IMAGE_GALLERY: '/api/block_types/IMAGE_GALLERY',
  IMAGES: '/api/block_types/IMAGES',

  EVENT_HEADER: '/api/block_types/EVENT_HEADER',
  EVENT_RELATED: '/api/block_types/EVENT_RELATED',
  EVENT_RELATED_PAGE: '/api/block_types/EVENT_RELATED_PAGE',

  EXHIBITION_RELATED_PUBLICATIONS:
    '/api/block_types/EXHIBITION_RELATED_PUBLICATIONS',
  EXHIBITION_RELATED_EVENTS: '/api/block_types/EXHIBITION_RELATED_EVENTS',
  EXHIBITION_RELATED_EXHIBITIONS:
    '/api/block_types/EXHIBITION_RELATED_EXHIBITIONS',
  EXHIBITION_EXHIBITS: '/api/block_types/EXHIBITION_EXHIBITS',
  EXHIBITION_ARTISTS: '/api/block_types/EXHIBITION_ARTISTS',
  EXHIBITION_IMAGES: '/api/block_types/EXHIBITION_IMAGES',
  EXHIBITION_IMAGE_CAROUSEL: '/api/block_types/EXHIBITION_IMAGE_CAROUSEL',
  EXHIBITION_IMAGE_GALLERY: '/api/block_types/EXHIBITION_IMAGE_GALLERY',
  EXHIBITION_SINGLE_EXHIBIT: '/api/block_types/EXHIBITION_SINGLE_EXHIBIT',

  COLLECTION_PREVIEW: '/api/block_types/COLLECTION_PREVIEW',

  AUTHORS: '/api/block_types/AUTHORS',

  FORMSET: '/api/block_types/FORMSET',
  NEWSLETTER: '/api/block_types/NEWSLETTER',
  JOB_OFFERS: '/api/block_types/JOB_OFFERS',
  ARCHIVE_EVENTS: '/api/block_types/ARCHIVE_EVENTS',
  RELATED_NEWS: '/api/block_types/RELATED_NEWS',
  TEAM: '/api/block_types/TEAM',

  CINEMA_CYCLE: '/api/block_types/CINEMA_CYCLE',
  CINEMA_EVENTS: '/api/block_types/CINEMA_EVENTS',
  CINEMA_AGENDA: '/api/block_types/CINEMA_AGENDA',
}
