import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import _block from './_block'
import { eventRelated } from './_blocks/_eventRelated'
import mediaSchema from '_schema/media'

const schema = {
  endpoint: '/api/news',
  access: 'news',
  resource: {
    definition: '#News',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          label: {},
          lead: {
            type: FIELD_TYPES.TINYMCE,
          },
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          alt: {},
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/aktualnosci/:slug',
              [LANGS_IRI.EN]: '/en/news/:slug',
            },
          },
        },
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: mediaSchema.endpoint,
        thumbs: {
          endpoint: '/api/news_thumbs',
        },
        validate: ['maxSize'],
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: { alt: media.translations[LANGS_IRI.PL].alt },
            [LANGS_IRI.EN]: { alt: media.translations[LANGS_IRI.EN].alt }
          })
        }
      },
      publishedAt: {
        type: 'date',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/news_blocks',
      definition: '#NewsBlock',
      types: [..._block('news').types, eventRelated(false, 'news')],
    },
  },
}

export default schema
