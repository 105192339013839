import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Dialog, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { ThumbCollection } from 'components/thumb'
import { translate } from '_helpers/translate'

const useStyles = makeStyles({
  emptyResults: {
    margin: 10,
    textAlign: 'center',
  },
  dialogContent: {
    marginBottom: 15,
  },
  paper: {
    maxHeight: '90vh',
  },
  paperFullWidth: {
    width: 'auto',
  },
})

export const ThumbsDialog = ({
  endpoint,
  parentIri = null,
  originalPhotoUrl = false,
  disabled = false,
  isOpen,
  handleToggle,
  cropperNodeRef = null,
  classes = {},
}) => {
  const defaultClasses = useStyles()

  return (
    <Dialog
      open={isOpen}
      onClose={handleToggle}
      fullWidth={true}
      maxWidth="lg"
      classes={{
        paper: clsx(defaultClasses.paper, classes.paper),
        paperFullWidth: clsx(
          defaultClasses.paperFullWidth,
          classes.paperFullWidth
        ),
      }}
    >
      <DialogTitleWithClose onClose={handleToggle}>
        {translate('T_GENERAL_PHOTO_THUMBS')}
      </DialogTitleWithClose>
      <DialogContent
        className={clsx(defaultClasses.dialogContent, classes.dialogContent)}
      >
        {parentIri && originalPhotoUrl ? (
          <ThumbCollection
            endpoint={endpoint}
            parentIri={parentIri}
            originalPhotoUrl={originalPhotoUrl}
            disabled={disabled}
            cropperNodeRef={cropperNodeRef}
          />
        ) : (
          <div
            className={clsx(defaultClasses.emptyResults, classes.emptyResults)}
          >
            {translate('T_GENERAL_PHOTO_THUMBS_EMPTY')}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

ThumbsDialog.propTypes = {
  endpoint: PropTypes.string.isRequired,
  parentIri: PropTypes.string,
  originalPhotoUrl: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
  cropperNodeRef: PropTypes.object,
  classes: PropTypes.shape({
    emptyResults: PropTypes.string,
    dialogContent: PropTypes.string,
    paper: PropTypes.string,
    paperFullWidth: PropTypes.string,
  }),
}
