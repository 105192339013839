import React from 'react'
import { downloadFile } from '_helpers/downloadFile'
import moment from 'moment'
import schema from '_schema/importEntry'

const DOWNLOAD_FORMAT = 'YYYY-MM-DD'
const VIEW_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const LogFileCell = ({ resource }) => {
  const handleDownload = (e) => {
    e.preventDefault()

    const url = schema.log_endpoint.replace(':uuid', resource.uuid)

    downloadFile(url, `import-log-${moment(new Date(resource.updatedAt)).format(DOWNLOAD_FORMAT)}.log`)
  }

  return (
    <div>
      <div>{moment(new Date(resource.updatedAt)).format(VIEW_FORMAT)}</div>
      {resource.logFileName && (
        <button onClick={handleDownload}>pobierz log</button>
      )}
    </div>
  )
}
