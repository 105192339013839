import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import serieScheme from './publishingHouseSerie'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/publishing_house_publications',
  access: 'publishingHousePublication',
  resource: {
    definition: '#PublishingHousePublication',
    properties: {
      title: {
        type: FIELD_TYPES.STRING,
        description: 'Title',
      },
      iksorisId: {
        type: FIELD_TYPES.IKSORIS,
        endpoint: '/api/iksoris/events',
        titleAccessor: 'name',
        label: 'Wydarzenie',
      },
      serie: {
        type: FIELD_TYPES.RESOURCE,
        endpoint: `${serieScheme.endpoint}?pagination=false`,
        titleAccessor: `translations.${
          LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
        }.title`,
      },
    },
  },
}

export default schema
