import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const eventHeader = () => ({
  name: BLOCK_TYPES_IRI.EVENT_HEADER,
  label: 'Wydarzenie: nagłówek',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
  },
})
