import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { fetchDataHandleAuthError } from '../../../_helpers/fetchDataHandleAuthError'
import { notification } from '../../../_helpers/notification'
import { translate } from '../../../_helpers/translate'
import { prop } from '../../../_helpers/prop'

const useStyles = makeStyles({
  input: {
    width: '300px',
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
  input_horizontal: {
    width: 200,
    height: 15,
    marginLeft: 4,
    marginRight: 4,
    '& input': {
      padding: 5,
    },
  },
  autocomplete_input: {
    height: 40,
    padding: '5px !important',
  },
  label: {
    '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 14px) scale(1)',
    },
  },
})

export const ResourceFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  classes = {},
  endpoint,
  titleAccessor = 'title',
  horizontal = false,
}) => {
  const [choices, setChoices] = useState([])

  const handleChange = (e, option) => {
    const value = option ? option.value : null
    setFilter(name, value || null)
  }

  console.log(endpoint)

  useEffect(() => {
    if (!endpoint) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, setChoices])

  const options = choices.map(resource => ({
    value: resource['@id'],
    title: translate(prop(resource, titleAccessor)),
  }))

  const defaultClasses = useStyles()

  return (
    <Autocomplete
      id="combo-box-demo"
      getOptionLabel={option => option.title || '<brak nazwy>'}
      className={
        horizontal
          ? clsx(defaultClasses.input_horizontal, classes.input_horizontal)
          : clsx(defaultClasses.input, classes.input)
      }
      onChange={handleChange}
      options={options}
      value={
        value
          ? {
              value: value,
              title:
                options.find(option => option.value === value)?.title || '',
            }
          : null
      }
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          label={title}
          variant="outlined"
          className={horizontal ? defaultClasses.label : ''}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: horizontal ? defaultClasses.autocomplete_input : '',
            },
          }}
        />
      )}
    />
  )
  /*
  return (
    <TextField
      name={`filter-text-${name}`}
      value={value || ''}
      helperText={title}
      className={clsx(defaultClasses.input, classes.input)}
      disabled={disabled}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      size="small"
      variant="filled"
    />
  )*/
}

ResourceFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  handleEnter: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
  endpoint: PropTypes.string,
  titleAccessor: PropTypes.string,
  horizontal: PropTypes.bool,
}
