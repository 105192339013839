import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const blogEntries = () => ({
  name: BLOCK_TYPES_IRI.BLOG_ENTRIES,
  label: 'Artykuły magazynu (5)',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
  },
})
