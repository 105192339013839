import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/formsets',
  access: 'formset',
  resource: {
    definition: '#Formset',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {
            label: 'Tytuł formularza',
          },
        },
      },

      email: {
        hint: 'Jeśli puste, będzie użyty domyślny adres z konfiguracji',
      },
      publishingDateFrom: {
        type: FIELD_TYPES.DATETIME,
      },
      publishingDateTo: {
        type: FIELD_TYPES.DATETIME,
      },
      stat: {},
    },
  },
}

export default schema
