import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { performTagRevalidation } from '_helpers/performTagRevalidation'
import { constants } from 'components/table/_state'

export const performManualOrderBetweenPageChange = (
  items,
  index,
  dispatch,
  direction,
  movement,
  revalidateTag
) => {
  dispatch({ type: constants.FETCH_START })

  fetchDataHandleAuthError(
    items[index]['@id'],
    'PUT',
    {
      body: JSON.stringify({
        ord:
          (movement === 'next' ? items[items.length - 1].ord : items[0].ord) +
          (direction === 'desc'
            ? movement === 'next'
              ? -1
              : 1
            : movement === 'next'
            ? 1
            : -1),
      }),
    },
    () => {
      dispatch({ type: constants.RELOAD })

      revalidateTag && performTagRevalidation(revalidateTag)

      notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
    },
    error => {
      dispatch({ type: constants.FETCH_FAILURE })
      notification(
        'error',
        error.response.violations.length ? 'Error' : error.response.detail,
        error.response.title
      )
    }
  )
}
