import React, { useState, useEffect } from 'react'
import { BlankForm } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { ManualNextRevalidatorButton } from 'components/ManualNextRevalidatorButton'
import { notification } from '_helpers/notification'
import { NotFound } from 'pages'
import schema from '_schema/config'

export const Edit = ({ schema: definitions }) => {
  const [state, setState] = useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      schema.endpoint,
      'GET',
      { signal },
      response => {
        setState({
          isFetching: false,
          resource: response,
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setState({
          isFetching: false,
          fetchError: true,
        })
      }
    )

    return () => controller.abort()
  }, [])

  const handleSuccess = resource => {
    setState(state => ({
      ...state,
      resource,
    }))
  }

  const { isFetching, fetchError, resource } = state

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <ManualNextRevalidatorButton type="config" uuid={resource.uuid} />
      <br />
      <BlankForm
        resource={resource}
        definitionSchema={definitions[schema.resource.definition]}
        customResourceSchema={schema.resource}
        method={resource['@id'] ? 'PUT' : 'POST'}
        url={resource['@id'] || schema.endpoint}
        handleSuccess={handleSuccess}
        showSubmitAndStayButton={false}
        showCancelButton={false}
        key={resource['@id'] || 'init'}
        fieldsFullWidth={true}
        width={700}
      />
    </Paper>
  )
}
