import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '_lib/langs'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const eventRelated = (forEvent = false, type = 'event') => {
  if (forEvent) {
    return {
      name: BLOCK_TYPES_IRI.EVENT_RELATED,
      label: 'Wydarzenie: powiązane',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {},
          },
        },
      },
    }
  } else {
    const LINKS_ENDPOINT = '/api/<TYPE>_block_related_links'
    const ENDPOINT = '/api/events'

    return {
      name: BLOCK_TYPES_IRI.EVENT_RELATED_PAGE,
      label: 'Wydarzenia powiązane',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {},
            text: {
              type: FIELD_TYPES.TEXTAREA,
              description: 'Opis',
            },
          },
        },
        isHideAllImages: {
          type: FIELD_TYPES.BOOLEAN,
          description: 'Pokazuj obrazki wydarzeń',
        },
        relatedLinks: {
          description: 'Wydarzenia',
          type: FIELD_TYPES.EMBEDDED_COLLECTION,
          endpoint: LINKS_ENDPOINT.replace('<TYPE>', type),
          titleAccessor: function(item) {
            if(item.event && item.event?.translations?.[LANGS_IRI.PL]?.title) {
              return item.event?.translations?.[LANGS_IRI.PL]?.title
            }
            return '--'
          },
          properties: {
            event: {
              label: 'Wydarzenie',
              type: FIELD_TYPES.AUTOCOMPLETE,
              titleProperty: 'title',
              searchProperty: 'title',
              endpoint: ENDPOINT,
              validate: ['required'],
            },
          },
        },
      },
    }
  }
}
