import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/formset_saves',
  resource: {
    definition: '#FormsetSave',
    properties: {
      dataForm: {
        type: FIELD_TYPES.DATETIME,
      },
      stat: {},
    },
  },
}

export default schema
