import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import { LANGS_IRI } from '_lib/langs'
import _block from './_block'
import { eventRelated } from './_blocks/_eventRelated'

const ENDPOINT = '/api/pages'

const schema = {
  endpoint: ENDPOINT,
  clone_endpoint: `${ENDPOINT}/clone`,
  access: 'page',
  resource: {
    definition: '#Page',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          description: {
            type: FIELD_TYPES.TINYMCE,
          },
          metaTitle: {},
          metaDescription: {
            type: FIELD_TYPES.TEXTAREA,
          },
          metaKeywords: {
            type: FIELD_TYPES.TEXTAREA,
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/:slug',
              [LANGS_IRI.EN]: '/en/:slug',
            },
          },
        },
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks',
      definition: '#PageBlock',
      types: [..._block('page').types, eventRelated(false, 'page')],
    },
    menuEntries: {
      endpoint: '/api/page_menu_entries',
      definition: '#PageMenuEntry',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {},
            link: {},
          },
        },
        page: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: `translations.${LANGS_IRI.PL}.title`,
          searchProperty: 'translations.title',
          endpoint: ENDPOINT,
        },
      },
    }
  },
}

export default schema
