import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'
import { LANGS_IRI } from '_lib/langs'

const ITEMS_ENDPOINT = '/api/<TYPE>_block_news'

export const relatedNews = (type) => ({
  name: BLOCK_TYPES_IRI.RELATED_NEWS,
  label: 'Aktualności powiązane',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
      },
    },
    news: {
      description: 'Aktualności',
      type: FIELD_TYPES.EMBEDDED_COLLECTION,
      endpoint: ITEMS_ENDPOINT.replace('<TYPE>', type),
      titleAccessor: (resource) =>
        resource?.news?.translations[LANGS_IRI.PL]?.title ||
        '<Brak aktualności lub tytułu>',
      properties: {
        news: {
          type: FIELD_TYPES.AUTOCOMPLETE,
          titleProperty: 'title',
          searchProperty: 'title',
          description: 'Aktualność',
          validate: ['required'],
          endpoint: '/api/news',
        }
      },
    },
  },
})
