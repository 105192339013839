import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/cinema_event_schedules',
  resource: {
    definition: '#CinemaEventSchedule',
    properties: {
      time: {
        type: FIELD_TYPES.DATETIME,
        description: 'Czas',
        validate: ['required'],
      },
    },
  },
}

export default schema
