import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

export const text = () => ({
  name: BLOCK_TYPES_IRI.TEXT,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT',
  properties: {
    translations: {
      type: FIELD_TYPES.TRANSLATION,
      properties: {
        title: {},
        upperTitle: {
          description: 'Górny prawy tytuł',
          type: FIELD_TYPES.STRING,
        },
        lowerTitle: {
          description: 'Dolny lewy tytuł',
          type: FIELD_TYPES.STRING,
        },
        text: {
          description: 'Tekst',
          type: FIELD_TYPES.TINYMCE,
        },
        // subtext: {
        //   description: 'Tekst 2',
        //   type: FIELD_TYPES.TINYMCE,
        // },
        ctaTitle: {
          description: 'CTA: tytuł',
          type: FIELD_TYPES.STRING,
        },
        ctaLink: {
          description: 'CTA: link',
          type: FIELD_TYPES.STRING,
        },
      },
    },
  },
})
