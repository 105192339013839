import FIELD_TYPES from 'components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/translator_entries',
  access: 'translatorEntry',
  resource: {
    definition: '#TranslatorEntry',
    properties: {
      code: {
        validate: ['required'],
      },
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {
            type: FIELD_TYPES.TEXTAREA,
          },
        },
      },
    },
  },
}

export default schema
