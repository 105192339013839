import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Title } from 'components/Title'
import { CustomButton } from 'components/CustomButton'
import { AddCircleOutline } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5em',
  },
  buttons: {
    '&> *': {
      marginRight: 10,
    },
    '&> *:last-child': {
      marginRight: 0,
    },
  },
  buttonTitle: {
    marginLeft: '.7em',
  },
})

export const CollectionHeader = ({
  children,
  buttonTitle,
  buttonPath,
  hideButton = false,
  additionalButtons = null,
  classes = {},
}) => {
  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      <Title>{children}</Title>
      {!hideButton && (
        <div className={clsx(defaultClasses.buttons, classes.buttons)}>
          <CustomButton path={buttonPath}>
            <AddCircleOutline />
            <span
              className={clsx(defaultClasses.buttonTitle, classes.buttonTitle)}
            >
              {buttonTitle}
            </span>
          </CustomButton>
          {additionalButtons}
        </div>
      )}
    </div>
  )
}

CollectionHeader.propTypes = {
  children: PropTypes.node,
  buttonTitle: PropTypes.string.isRequired,
  buttonPath: PropTypes.string.isRequired,
  hideButton: PropTypes.bool,
  additionalButtons: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string,
    buttons: PropTypes.string,
    buttonTitle: PropTypes.string,
  }),
}
